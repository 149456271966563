.news {
  &__container {
    max-width: 67rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  &__articleContainer {
    width: 100%;
  }

  hr {
    border: 2px solid $background-light;
  }

  &__bottomItemsContainer {
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    & > h2 {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: $secondary;
      margin: 60px 0;
    }
  }
}
.bottomItem {
  margin-bottom: 40px;
  border-radius: 10px;
  border: 1px solid $border;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__imgContainer {
     img {
      width: 100%;
      display: block;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__textContainer {
    position: relative;
  }

  &__text {
    padding: 20px 25px 20px 25px;

    &--title {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 700;
      & > a {
        color: $secondary!important;
        text-decoration: none;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    & > a {
      text-decoration: none;
      line-height: 50px;
      color: $light!important;

      &:hover {
        color: $primary!important;
      }
    }
  }
}


.carouselButtonGroup {
  & > button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transition: all 0.3s ease;

    &:hover {
      color: $primary;
      svg > path {
        fill: $primary !important;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__left {
    transform: rotate(180deg);
    left: -10px;
  }

  &__right {
    right: -10px;
  }
}

@media only screen and (min-width: 768px) {
  .news {
    &__articleContainer {
      width: 64vw;
      margin: 0 auto;
    }

    &__bottomItemsContainer {
      margin: 0 auto 30px auto;

      &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
  .bottomItem {
    margin: 0 10px;

    &__imgContainer {
      img {
        object-fit: cover;
        height: 25vw;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .carouselButtonGroup {
    &__left {
      left: -4vw;
    }

    &__right {
      right: -4vw;
    }
  }

  .bottomItem {
    &__imgContainer {
      img {
        height: 16vw;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .bottomItem {
    &__imgContainer {
      img {
        height: 15rem;
      }
    }
  }
}