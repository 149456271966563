.groupedTimelineContainer {
  padding: 40px 0;
  color: $secondary;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.groupedTimeline {
  & > div.vertical-timeline {
    padding: 0;

    &:before {
      width: 0;
      border-right: 3px dashed $menu-background;
      background: transparent;
      margin-top: 20px;
      height: calc(100% - 20px);
    }
  }

  &__itemSingleActions {
    text-align: center;
  }

  &__dataContentMobile {
    padding-left: 30px;
    padding-right: 10px;
  }

  &__itemSingle {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &__date {
    opacity: 1 !important;
  }

  &__groupItemName {
    text-align: left;
    font-weight: 500;
    line-height: 1.1;
    font-size: 26px !important;
    font-family: "Ubuntu", sans-serif;
    margin: 0;
  }

  &__groupItemContent {
    text-align: left;
    margin-bottom: 10px;

    & > p {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
  }

  &__groupItemActions {
    text-align: left;
  }

  &__groupItemLink {
    text-decoration: none;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: $secondary !important;
    line-height: 26px;
    margin-bottom: 40px;
    transition: box-shadow 0.42s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      cursor: pointer;
      box-shadow: inset 0 0 0 2px #2a3030;
    }
  }

  &__item {
    display: flex;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &--img {
      padding-right: 15px;

      & > img {
        max-width: 50px;
        height: auto;
      }
    }

    &--noImg {
      padding-right: 65px;
    }

    &--content {
      & > div > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (min-width: 1170px) {
  .groupedTimeline {
    &__groupItemName {
      font-size: 36px !important;
      margin-top: 0;
    }

    &__item {
      &--img {
        padding-right: 20px;

        & > img {
          max-width: 80px;
        }
      }

      &--noImg {
        padding-right: 100px;
      }
    }

    &__groupItemContent {
      margin-bottom: 25px;
    }

    &__groupItemLink {
      margin-bottom: 10px;
      padding: 15px 30px;
    }
  }
}
