@import '../colors';


.phoneNumberWithPrefix {
  display: flex;

  .Select__control {
    margin-bottom: 0;
  }

  input {
    margin-bottom: 0;
    height: 100%;
  }

  & > label > .Select {
    width: 110px;
    max-width: 110px;
    margin-right: 10px;
    z-index: 3;
  }

  .Select-value {
    padding-left: 20px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
}