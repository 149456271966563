.advantages {
  .carousel-root {
    width: 100%;
  }

  &__slider-control-arrow-button {
    background: transparent;
    border: none;
    outline: none;
    padding: 5px;
    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translate(50%);

    &.next {
      right: 25px;
    }

    &.prev {
      left: -5px;
    }

    &.disabled {
      display: none;
    }
  }

  &__container {
    display: flex;
    margin: auto;
    padding-top: 20px;
  }

  &__card {
    padding: 2rem;
    transition: all 0.5s ease;
    margin: 0 15px 15px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    background-color: $white;

    &__img {
      filter: grayscale(100%);
      width: 100%;
    }

    &__icon {
      margin-left: 0.3rem;
    }

    &__header {
      line-height: 1.08;
      letter-spacing: 0.2px;
      color: $greyish-brown;
      font-size: 1.25rem;
      margin-top: 0.8rem;
      margin-bottom: 0;
      min-height: 44px;
    }

    &__content {
      line-height: 1.88;
      letter-spacing: 0.2px;

      color: $warm-grey-text;
      font-size: 0.9rem;
    }

    &--bold {
      font-size: 600;
    }
  }

  &__card:hover {
    background-color: $white;
    box-shadow: 0px 7px 21px 0 rgba(0, 0, 0, 0.05);

    svg {
      path {
        fill: $icon-orange !important;
      }
    }
  }

  &__card:hover > .advantages__card__img {
    filter: grayscale(0%);
  }
}

@media only screen and (min-width: 1024px) {
  .advantages {
    &__container {
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: 15px;
      margin-right: 15px;
    }

    &__card {
      width: calc(25% - 15px);
      box-sizing: border-box;
      margin: 0 0 20px;

      &--active {
        background-color: $white;
        box-shadow: 0px 26px 79px 0 rgba(0, 0, 0, 0.13);
      }
    }
  }
}

@media only screen and (min-width: 1360px) {
  .advantages {
    &__card {
      background-color: $white;
    }
  }
}
