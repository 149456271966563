.numbered4Columns {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    max-width: 100%;
    margin: 0 1.25rem;
    padding-bottom: 1rem;
  }

  &__header {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.3px;
    color: $orangey-yellow;
    margin: 0 auto;
  }

  &__content {
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: left;
    color: $greyish-brown-elements;
    font-size: 0.9rem;
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .numbered4Columns {
    &__container {
      justify-content: space-evenly;
      margin: auto;
    }

    &__item {
      width: 35%;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .numbered4Columns {
    &__content {
      line-height: 2.25;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .numbered4Columns {
    &__container {
      justify-content: left;
    }

    &__item {
      margin: 0 1.25rem 0 0;
      max-width: 23%;
    }
  }
}
