.pages {
  display: flex;
  justify-content: center;

  .rc-pagination {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 12px;
  }

  button.rc-pagination-item-link {
    background-color: transparent;
    border: none;
  }

  .rc-pagination-item {
    border-radius: 50%;
    font-weight: 600;
    line-height: 28px;
    border: 1px solid transparent;
    box-sizing: border-box;

    &:hover {
      color: $secondary;
      border: 1px solid $secondary;
    }

    &:focus {
      outline: none;
    }
  }

  .rc-pagination-jump-prev:hover:after,
  .rc-pagination-jump-next:hover:after {
    color: $primary;
  }

  .rc-pagination-item {
    &:hover {
      & > a {
        color: $secondary;
      }
    }

    & > a {
      color: $input-text-color;
    }
  }

  .rc-pagination-item.rc-pagination-item-active {
    background-color: $secondary;

    & > a {
      color: $light
    }
  }
  li.rc-pagination-next > button.rc-pagination-item-link,
  li.rc-pagination-prev > button.rc-pagination-item-link {
    font-size: 26px;
    line-height: 26px;

    &:hover {
      color: $primary;
    }
  }
}

@media only screen and (min-width: 768px) {
  .pages {
    justify-content: flex-end;
  }
}
