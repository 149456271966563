.payUContainer {
  background-color: $background-light;
  padding: 40px 15px;

  .payUButton {
    background-color: #a6c307;
    width: 300px;
    height: 70px;
    border-radius: 15px;
    color: $light;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 10px;

    svg {
      fill: $light;
    }
  }

  .payUWidget {
    font-size: 20px;

    & > span > a {
      text-decoration: none !important;
      color: #a6c307 !important;
    }
  }

  .payUButtonLogo {
    width: 100px;
    height: 50px;
    background-position: center;
    background-size: cover;
    background-image: url("/blocks/PayU/logoPayU.png");
  }
  .payUButtonLine {
    width: 1px;
    background-color: white;
    height: 35px;
    margin-left: 20px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .payUContainer {
    & > .desktop-container {
      display: flex;
      align-items: center;

      & > div:nth-child(1) {
        width: 60%;
      }
      & > div:nth-child(2) {
        width: 40%;
      }
    }
  }
}
