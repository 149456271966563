.coursesList {
  &__category {
    display: none;
    text-align: center;
    margin: 40px auto;
    max-width: 730px;

    & > h2 {
      font-size: 36px;
      font-weight: 500;
      color: $secondary;
      margin: 0;
    }

    & > p {
      font-size: 20px;
      font-weight: 400;
      color: $secondary;
    }
  }

  &__filters {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    .select-label {
      font-size: 13px;
      margin-left: 10px;
      color: $additional-text;
      background-color: $light;
      position: absolute;
      left: 60px;
      top: 0;
      padding: 0 2px;
      z-index: 2;
    }

    .Select__menu {
      z-index: 5;
    }

    .Select__control {
      border-radius: 24px;
      border-color: $additional-text;
      margin: 10px 15px 0 0;
      height: 48px;

      &:last-of-type {
        margin-right: 0;
      }

      &--is-focused {
        border-color: $primary!important;
        box-shadow: none!important;
      }
    }

    .Select__placeholder {
      line-height: 46px;
      color: $secondary;
      padding-left: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Select__value-label,
    .Select__value {
      line-height: 48px;
    }

    .Select__menu-outer {
      font-size: 14px;
      z-index: 3;

      div {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }

    .Select__input {
      height: 48px;

      & > input {
        line-height: 30px;
      }
    }
  }

  &__anchors {
    padding: 0 15px;
    background-color: $background-light;
    margin: 15px auto;
    display: none;
  }

  &__filterSelect {
    width: 100%;
    padding: 0 45px;
    margin-right: 15px;
    margin-top: 15px;
    position: relative;
  }

  &__mobileFilters {
    position: relative;

    &Label {
      font-size: 26px;
      color: $secondary;
      font-weight: 500;
      padding-top: 60px;
      text-align: center;
    }

    &Close {
      background: none;
      border: none;
      outline: none;
      position: absolute;
      top: 20px;
      right: 20px;

      & > svg {
        fill: $secondary;
      }
    }

    &Open {
      box-shadow: 0px 3px 10px #00000029;
      color: $secondary !important;
      font-weight: 500;
      font-size: 14px;
      position: fixed !important;
      bottom: 110px;
      left: 15px;
      display: flex !important;
      align-items: center;
      padding: 15px 20px !important;

      & > svg {
        fill: $secondary !important;
        margin-right: 10px;
      }
    }
  }

  &__filterButton {
    text-align: center;

    & > button {
      width: 100%;
      padding: 0 45px;
    }
  }

  .productSection {
    display: flex;
    color: $secondary;
    margin-bottom: 30px;
    padding: 20px 15px 0;

    &__subTitle {
      text-transform: uppercase;
      color: $additional-text;
    }

    &__bestsellerLabel {
      background-color: $primary;
      color: $light;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 16px;
      display: inline-block;
    }

    &__description {
      width: 40%;
      box-sizing: border-box;
      padding: 15px 10px 15px 0;

      a {
        text-decoration: none;
        color: $secondary;
        line-height: 27px;

        &:hover {
          color: $light;
        }
      }

      .modern-button {
        font-weight: 500;
        font-size: 14px;
      }

      & > h2 {
        font-size: 36px;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      & > p {
        font-size: 18px;
        line-height: 30px;
      }
    }

    &__attributes {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      & > img.productCover {
        width: calc(45% - 10px);
        height: auto;
      }
    }

    &__attributesBox {
      position: relative;
      width: 100%;

      &Arrow {
        width: 25px;
        height: 25px;
        position: absolute;
        z-index: 2;
        border-left: 1px solid #dfdfdf;
        border-top: 1px solid #dfdfdf;
        top: 50%;
        transform: rotate(-45deg);
        left: -14px;
        background: white;
      }

      &--title {
        background-color: $primary;
        color: $secondary;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        box-sizing: border-box;
        padding: 7px 20px;
      }

      &--content {
        display: flex;
      }

      &--list {
        padding: 25px 5px 25px 20px;
        word-break: break-word;
      }

      &--iconSection {
        padding: 10px;

        & > img {
          max-width: 50px;
        }
      }

      &--row {
        font-size: 14px;
        margin-bottom: 10px;
        display: flex;

        & > span.attributesValues {
          display: flex;
          flex-wrap: wrap;
        }

        .labelWitDot {
          &:after {
            content: "\2022";
            color: $primary;
            margin: 0 5px;
          }

          &:last-of-type:after {
            display: none;
          }
        }

        svg {
          margin-right: 10px;

          & > g {
            .a {
              fill: $secondary !important;
            }
          }

          & > path.a {
            fill: $secondary !important;
          }
        }
      }
    }
  }

  .categoryAnchors {
    display: flex;
    align-items: center;
    padding: 15px 0;

    &__label,
    &__item {
      padding: 0 15px;
      margin: 5px;
      user-select: none;
      cursor: pointer;
      display: flex;

      & > a {
        text-decoration: none;
        color: $additional-text !important;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: $secondary !important;
        }
      }
    }
  }

  .termsSection {
    padding: 25px 0;

    &__price {
      display: flex;
      flex-direction: column;

      & > span {
        display: block;
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    &__collapseButton {
      text-align: center;
      padding: 10px 0;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;

        & > div,
        & > span {
          cursor: pointer;
        }

        & > div {
          transition: all 0.2s ease-in-out;
        }

        & > span {
          margin-right: 10px;
        }
      }
    }

    &__item {
      border: 1px solid #0000001a;
      border-radius: 6px;
      margin-bottom: 10px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $secondary;
      font-weight: 500;
      background-color: $light;

      & > div {
        width: 12.5%;

        &:last-of-type,
        &:first-of-type {
          width: 25%;
        }

        &:before {
          content: "";
          height: 19px;
          border-right: 3px solid #0000001a;
          margin-right: 15px;
        }

        &:first-of-type:before {
          display: none;
        }
      }

      &Name {
        display: flex;
        align-items: center;

        & > img {
          margin-right: 20px;
          max-width: 45px;
        }
      }

      &Action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          max-width: 50%;
          box-sizing: border-box;
          padding-right: 10px;
        }

        & > a {
          text-decoration: none;
          line-height: 42px;
          height: 45px;
          color: $light;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    &Mobile {
      &__item {
        background-color: $light;
        border: 1px solid #0000001a;
        border-radius: 6px;
        position: relative;
        margin-bottom: 15px;

        &City {
          position: absolute;
          font-size: 12px;
          padding: 2px;
          background: $light;
          top: -10px;
          left: 10px;
          z-index: 2;
        }

        &Name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 25px 15px 15px;
          color: $secondary;
          font-weight: 500;

          & > div:first-of-type {
            display: flex;
            align-items: center;

            & > img,
            & > img {
              margin-right: 10px;
              max-width: 40px;
            }
          }
        }
      }

      &__mainAttributes {
        display: flex;
      }

      &__attributesSection {
        width: 50%;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;

        & > div {
          padding: 7px 15px;
        }

        &Label {
          background-color: $background-light;
          color: $additional-text;
          font-size: 12px;
        }

        &:first-of-type {
          border-right: 1px solid #0000001a;
        }
      }

      &__priceSection {
        & > .termsSectionMobile__attributesSectionLabel {
          padding: 7px 15px;
        }
      }

      &__priceValue {
        padding: 7px 15px;
        font-size: 14px;
        font-weight: 500;

        &--small {
          font-size: 11px;
        }
      }

      &__priceAction {
        padding: 7px 15px;
        text-align: center;

        & > a {
          text-decoration: none;
          line-height: 28px;
          color: $light;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

.productSectionMobile {
  padding: 20px 0;
  margin: 0 20px;
  color: $secondary;
  border-bottom: 1px solid $additional-text;

  &__name {
    display: flex;
    align-items: center;
    font-weight: 500;

    & > img {
      margin-right: 15px;
      max-width: 45px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__subTitle {
    text-transform: uppercase;
    color: $additional-text;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__bestsellerLabel {
    background-color: $primary;
    color: $light;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 16px;
    display: inline-block;
  }

  &__description {
    padding: 20px 0;

    & > .termsSection {
      .rah-animating > div,
      .rah-static > div {
        padding-top: 10px;
      }
    }


    & > h2 {
      font-size: 26px;
      font-weight: 500;
      margin: 5px 0;
    }
  }

  &__chevron {
    transition: all 0.2s ease-in-out;
  }

  &__action {
    text-align: center;
    a {
      color: $secondary !important;
      text-decoration: none;
      line-height: 26px;

      &:hover {
        color: $light;
      }
    }
  }

  &__attributesBox {
    &--iconSection {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;

      & > img {
        height: 150px;
      }
    }

    &--list {
      padding: 25px 5px 25px 5px;
      word-break: break-word;
    }
  }
}

@media only screen and (min-width: 768px) {
  .coursesList {
    &__category {
      display: block;
      scroll-margin-top: 130px;
    }

    &__filters {
      .select-label {
        font-size: 16px;
        color: $secondary;
        position: static;
        left: 0;
        top: 0;
        padding: 0;
        z-index: 1;
      }

      .Select__control {
        border-color: $secondary;

        .Select__value {
          padding-left: 15px;
        }
      }
    }

    &__filterSelect {
      width: 180px;
      padding: 0 15px;
    }

    .courseSelect {
      width: 240px;
    }

    &__filterButton {
      text-align: left;

      & > button {
        width: 180px;
        padding: 0 15px;
      }
    }

    &__anchors {
      display: block;
    }

    .termsSection {
      background-color: $background-light;
    }

    .productSection {
      &__attributes {
        width: 60%;
      }

      &__attributesBox {
        box-shadow: 0px 3px 10px #00000029;
        border: 1px solid #dfdfdf;
        max-width: 305px;
        margin-left: 20px;

        &--content {
          justify-content: space-between;
        }
      }
    }
  }
}

@media only screen and (min-width: 1290px) {
  .coursesList {
    &__filterSelect:first-of-type {
      padding-left: 0;
    }

    .productSection {
      padding: 60px 0 0;
    }

    .categoryAnchors {
      &__label {
        color: $secondary;
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
}
