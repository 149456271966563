.articlesList {
  &__container {
    margin: 0 auto;
  }
}

.additionalHtml {
  width: 100%;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

@media only screen and (min-width: 768px) {
  .articlesList {
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .additionalHtml {
    margin-left: 0;
    margin-right: 0;
  }
}
