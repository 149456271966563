@import "../colors";
@import "../BaseColors";

.main-header {
  z-index: 99;
  background-color: $menu-background;
  color: $light;
  width: 100%;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;

  &__container {
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    min-height: 50px;
  }

  &__container_desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__right-section {
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      margin-right: 10px;
      text-decoration: none;
      display: flex;
      align-items: center;
      &:last-of-type {
        margin-right: 0;
      }
    }

    .contact-button {
      background-color: transparent;
      border-color: $light;
      color: $light;
      font-weight: normal;
      font-size: 14px;
      border-width: 1px;

      & > svg {
        fill: $light;
        margin-right: 5px;
      }

      &:after {
        background-color: transparent;
      }
      &:before {
        background-color: $primary;
      }
      &:hover {
        color: $secondary;
        border-color: $primary;
        background-color: $primary;

        & > svg {
          fill: $secondary;
        }
      }
    }
    .shop-button {
      background-color: $primary;
      border-color: $primary;
      color: $secondary;
      font-weight: normal;
      font-size: 14px;
      border-width: 1px;

      & > svg {
        fill: $secondary;
        margin-right: 5px;
      }

      &:after {
        background-color: $primary;
      }
      &:before {
        background-color: $secondary;
      }
      &:hover {
        color: $light;
        border-color: $primary;
        background-color: $primary;

        & > svg {
          fill: $light;
        }
      }
    }
  }

  &__navigation {
    padding-left: 50px;
    margin-right: 25px;
    &Container {
      position: relative;
      width: calc(100% - 480px);

      @media (min-width: 1290px) {
        width: calc(100% - 400px);
      }
    }

    &--leftArrow,
    &--rightArrow {
      position: absolute;
      top: 17px;
      fill: $light;
      cursor: pointer;
    }

    &--leftArrow {
      left: 35px;
      transform: scaleX(-1);
    }

    &--rightArrow {
      right: 10px;
    }
  }
  &__logoSection {
    & > span {
      padding: 0 5px;
    }

    .mainLogo {
      width: 84px;
    }
  }
}

.navigationMenu {
  list-style: none;
  user-select: none;

  & > li {
    display: inline-block;
    align-items: center;
    padding: 17px 26px 17px 0;

    &:last-of-type {
      padding-right: 0;
    }
  }

  & > li > div {
    display: none;
  }

  & > li:hover > a,
  span {
    color: $primary;
  }

  & > li:hover > div {
    display: block;
  }

  & > li > a,
  & > li > span {
    color: $light;
    text-decoration: none;
    transition: all 0.2s ease;

    &.active {
      text-shadow: 0 0 0.65px $dark-grey, 0 0 0.65px $dark-grey;
    }
  }

  & > li > a:hover {
    text-shadow: 0 0 0.65px $dark-grey, 0 0 0.65px $dark-grey;
  }

  & > li > span:hover {
    cursor: default;
  }
}

.navigationSubMenu {
  position: absolute;
  top: 50px;
  z-index: 99;

  ul {
    list-style-type: none;
    display: flex;
    padding: 0;
  }

  & > div {
    width: 100%;
    box-sizing: border-box;
  }

  &__additionalTextLabel {
    font-size: 12px;
    color: $light;

    & > span {
      border-radius: 10px;
      margin-left: 5px;
      color: $secondary;
      @media (min-width: 768px) {
        color: $menuLabel-gray;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__additionalTextWithBgLabel {
    margin-left: 3px;
    align-items: flex-end;
  }

  &__firstLevel {
    font-size: 14px;

    & > ul {
      list-style-type: none;
      display: flex;
      padding: 0;
    }

    &Item {
      overflow: auto;
      max-height: 70vh;
      border-right: 1px solid grey;
      display: block;
      box-shadow: 0 2px 4px 0 rgba(199, 199, 199, 0.5);
      background-color: $menu-background;
      border-radius: 0px 0px 5px 5px;

      &:nth-child(even) {
        background-color: $menu-background;
      }

      &:last-of-type {
        border: none;
      }

      & > a,
      & > span {
        color: $menuLabel-gray;
        text-decoration: none;
        display: block;

        &:first-of-type {
          margin-top: 0;
        }

      }

      & > a:hover {
        text-shadow: 0 0 0.65px $dark-grey, 0 0 0.65px $dark-grey;

      }

      & > span:hover {
        cursor: default;
      }
    }

    &--bold {
      text-transform: uppercase;
      font-weight: bold;
      font-size:16px;
      padding:15px 30px 10px 30px;

    }
  }

  &__secondLevel {
    font-size: 14px;
    width: 100%;

    & > ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    &Container {
      display: flex;
      flex-direction: column;
    }

    &Item {


      &:first-of-type {
        margin-top: 0;
      }

      &WithImg {
        width: calc(100% - 50px);
      }

      &Img {
        width: 40px;
        min-width: 40px;
        margin-right: 10px;

        & > img {
          width: 100%;
        }
      }

      & > a,
      & > span {
        color: $secondary;
        text-decoration: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;


        @media (min-width: 768px) {
          align-items: flex-start;
          flex-direction: column;
          color: $light;
          padding: 10px 30px;
        }
      }
      @media (min-width: 768px) {
        & > a:hover {
          background-color: #424747;
        }
      }
      & > span:hover {
        cursor: default;
      }

      &--bold {
        margin: 15px 0 5px;
        font-weight: bold;

        &:first-of-type {
          margin-top: 0;
        }

        & > a,
        & > span {
          position: relative;
          margin-left: 15px;

          &:before {
            content: "";
            width: 5px;
            height: 5px;
            background: $dark-grey;
            position: absolute;
            top: 5px;
            left: -15px;
          }
        }

        & > span {
          cursor: default;
          color: $dark-grey;
        }
      }

      &--mobile {
        border-top: 1px solid $grey-border;
        padding-top: 10px;
        font-weight: bold;

        &:first-of-type {
          border-top: none;
        }
      }
    }
  }
}

.fixed-menu-padding {
  padding-top: 46px;
}

.fixed-menu-padding-with-top-bar {
  padding-top: 50px;
}

.top-bar-padding-for-menu-button {
  top: 80px !important;
}

@media (min-width: 768px) {
  .fixed-menu-padding-with-top-bar {
    padding-top: 106px;
  }

  .main-header {
    position: fixed;

    &__languages {
      display: block;
      padding-right: 10px;
      text-transform: uppercase;
      cursor: pointer;

      &--active {
        color: $primary;
      }
    }

    &__logoSection {
      .mainLogo {
        width: 120px;
      }
    }

    &__partner {
      font-size: 14px;

      & img.partnerLogo {
        height: 20px;
        margin: 0 40px 0 10px;
      }
    }
  }

  .navigationMenu::-webkit-scrollbar {
    display: none;
  }

  .navigationMenu {
    padding: 0;
    height: 100%;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
  }

  .fixed-menu-padding {
    padding-top: 50px;
  }

  .fixed-menu-padding-without-items {
    padding-top: 92px;
  }

  .fixed-menu-padding-with-top-bar {
    padding-top: 110px;
  }

  .submenu-top-with-top-bar {
    top: 110px;
  }
}

@media (min-width: 1290px) {
  .main-header {
    width: 100%;

    &__container {
      width: 1270px;
      padding: 0;
    }

    &__right-section {
      width: 100%;
    }
  }

  .navigationSubMenu {
    &__secondLevel {
      margin-left: 30px;

      &:first-of-type {
        margin-left: 0;
      }

      &Container {
        flex-direction: row;
      }
    }
  }
}

.closeMenuButton {
  font-size: 48px;
  transform: rotate(45deg);
  border: none;
  background-color: $light;
}

.openMenuButton {
  background-color: $white;
  border: 1px solid $grey-border;
  outline: none;
  font-size: 26px;

  z-index: 99;
  position: absolute;

  &.open {
    top: 66px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;

    & > svg {
      margin: 0;
    }
  }

  &.close {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    transform: rotate(45deg);
    left: calc(80vw + 5px);
    top: 10px;
    padding: 0;
    z-index: 10000;
  }
}

.mobileMenu {
  &__headerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 10px 25px;

    & > img {
      width: 100px;
    }
  }

  &__itemsGroup {
    margin-left: 15px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 15px 25px;

    & > a,
    & > span {
      font-size: 16px;
      font-weight: 500;
      color: $dark-grey;
      text-decoration: none;
      font-family: "Ubuntu", sans-serif;
    }

    &--transparent {
      background-color: transparent;
    }
  }
  &__firstLevelSubItem {
    border-color: transparent;
  }
  &__languages {
    text-transform: uppercase;
    cursor: pointer;
    padding-top: 20px;
    padding-left: 20px;
    &--active {
      color: $primary;
      font-weight: bold;
    }
  }

  &__itemHeader {
    padding: 10px;
    border-bottom: 1px solid $grey-border;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-family: "Ubuntu", sans-serif;

    svg.rotateArrow {
      transform: rotate(180deg);
      margin-right: 20px;
    }
  }

  &__secondLevel {
    padding: 0;

    & > li {
      padding: 5px 20px;
      font-size: 16px;
      height: auto;
    }
  }
}

.mainDrawer > div.drawer-content-wrapper > div.drawer-content {
  background-color: $light;
}

.back-to-top {
  position: fixed;
  width: 3rem;
  right: -2px;
  z-index: 50;
  bottom: 10rem;
  cursor: pointer;
  opacity: 0;

  &--hidden {
    opacity: 0;
    -webkit-animation: fadeOutRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
    animation: fadeOutRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }

  &--visible {
    opacity: 0.8;
    -webkit-animation: fadeInRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
    animation: fadeInRight 1s cubic-bezier(0.77, 0, 0.175, 1) 1;
  }
}

.bottomMobileMenu {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: $secondary;
  color: $white;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &__item {
    flex: 1 1 0;
    border-left: 1px solid rgba(255, 255, 255, 0.21);
    height: 58px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;

    &:first-of-type {
      border-left: none;
    }

    & > a,
    div {
      text-decoration: none;
      color: $white;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      align-items: center;
      & > img {
        width: 25px;
        margin-bottom: 2px;
      }
    }
  }
}

.active > img.bottomMobileMenu__icon--white {
  opacity: 1;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    right: -45px;
  }
  to {
    opacity: 0.8;
    right: -2px;
  }
}

@keyframes fadeOutRight {
  from {
    right: -2px;
    opacity: 0.8;
  }
  to {
    right: -45px;
    opacity: 0;
  }
}

@-moz-keyframes marquee {
  0% {
    -moz-transform: translate3d(0%, 0, 0);
  }
  100% {
    -moz-transform: translate3d(-50%, 0, 0);
  }
}
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate3d(0%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
  }
}

@keyframes marquee {
  0% {
    -moz-transform: translate3d(0%, 0, 0);
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
  100% {
    -moz-transform: translate3d(-50%, 0, 0);
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
