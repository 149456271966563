.modes {
  padding: 20px 0;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  &__bigCard {
    background-color: $white;
    max-width: 340px;
    margin: 1rem;
    transition: all 0.3s ease;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid $light-grey;

    &__img {
      width: 100%;
      margin: auto;
      border-radius: 10px 10px 0 0;
    }

    &__header {
      font-weight: 600;
      padding: 0 25px;
      margin: 15px 0;
    }

    &__content {
      padding: 0 25px;
      margin-bottom: 25px;
    }
  }

  &__bigCard:hover {
    box-shadow: 0px 7px 21px 0 rgba(0, 0, 0, 0.05);
  }
}

@media only screen and (min-width: 768px) {
  .modes {
    padding-top: 20px;
    padding-bottom: 40px;

    &__bigCard {
      width: 240px;
      margin: 0 10px 0 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .modes {
    &__container {
      width: 1000px;
    }

    &__bigCard {
      margin-right: 10px;
      width: 320px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 1320px) {
  .modes {
    &__bigCard {
      width: 410px;
      max-width: 410px;
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
