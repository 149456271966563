@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700&display=swap&subset=latin-ext");
@import "colors.scss";
@import "./BaseColors.scss";

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: $secondary;
  font-size: 1rem;
  width: 100vw;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
  line-height: 1.1;
  font-family: "Ubuntu", sans-serif;
  margin: 0;
}

h4 {
  font-weight: 700;
}

.style-as-h1,
h1 {
  font-size: 38px;
}

.style-as-h2,
h2 {
  font-size: 26px;
}

.style-as-h3,
h3 {
  font-size: 22px;
}

.style-as-h4,
h4 {
  font-size: 18px;
}

.style-as-h5 {
  font-size: 16px;
}

.style-as-h1,
.style-as-h2,
.style-as-h3,
.style-as-h4,
.style-as-h4,
.style-as-h5 {
  display: block;
  font-weight: 500;
  line-height: 1.1;
  font-family: "Ubuntu", sans-serif;
  margin: 0;
}

.style-as-h4 {
  font-weight: 700;
}

.textContent-xs,
.textContent-sm,
.textContent-normal,
.textContent-lg,
.textContent-xlg {
  font-weight: 400;
  margin: 0;
  line-height: 1.4;
}

.textContent-xs {
  font-size: 12px;
}

.textContent-sm {
  font-size: 14px;
}

.textContent-normal {
  font-size: 16px;
}

.textContent-lg {
  font-size: 18px;
  line-height: 1.3;
}

.textContent-xlg {
  font-size: 18px;
  line-height: 1.3;
}

@media only screen and (min-width: 768px) {
  .style-as-h1,
  h1 {
    font-size: 64px;
  }

  .style-as-h2,
  h2 {
    font-size: 36px;
  }

  .style-as-h3,
  h3 {
    font-size: 24px;
  }

  .style-as-h4,
  h4 {
    font-size: 20px;
  }

  .textContent-lg {
    font-size: 20px;
  }

  .textContent-xlg {
    font-size: 24px;
  }
}

div[tabindex="-1"]:focus {
  outline: 0;
}

textarea:focus,
input:focus {
  outline: none;
}

.divider {
  width: 8.4375rem;
  margin: 2rem 0;

  &--white {
    background: $white-divider;
  }

  &--grey {
    background: $grey-divider;
  }

  &--full-width {
    width: 100%;
  }
}

/* Header */
.mainSiteHeader {
  margin: 0 auto;
  padding: 1.5rem 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3rem;
  letter-spacing: 0.6px;
  color: $greyish-brown-main;
  position: relative;
}

/* global background grey */
.mainBackgroundGrey {
  background-color: $white-background;
}

.paddingsForBlocks {
  padding-top: 0.625rem;
  padding-bottom: 2.75rem;
}

@media only screen and (min-width: 768px) {
  .pageTabNavigation {
    padding-top: 100px;
  }

  .mainSiteHeader {
    justify-self: center;
    font-size: 2rem;
    padding: 5.5rem 0 1.5rem 0;
  }
}

@media only screen and (min-width: 1024px) {
  .mainSiteHeader {
    font-size: 2.5rem;
  }

  .paddingsForBlocks {
    padding-top: 1.25rem;
    padding-bottom: 5.25rem;
  }

  .blocks__desktopWrapper {
    max-width: 67rem;
    margin: auto;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: unset;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border: 1px solid $grey-border;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: $white;
  border: 1px solid $icon-orange;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $white;
  border: 1px solid $icon-orange;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $icon-orange;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.clickable-group {
  color: $greyish-brown-main;
  display: flex;
  text-decoration: none;
  width: 100%;
}

/* Border Radius */
.border-sx-radius {
  border-radius: 0.7em !important;
}

/* Slide animations */

.slide-in {
  transform: translateX(100%);
  animation: slide-in 0.5s forwards;
}

.slide-out {
  transform: translateX(100%);
  animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-110%);
  }
}

.slide-in-back {
  transform: translateX(-100%);
  animation: slide-in-back 0.5s forwards;
}

.slide-out-back {
  transform: translateX(-100%);
  animation: slide-out-back 0.5s forwards;
}

@keyframes slide-in-back {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out-back {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(+150%);
  }
}
