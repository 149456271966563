.installments {
  &__cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__card {
    background-color: $white;
    margin-bottom: 1.5rem;
    min-height: 13rem;
    width: 80%;
    max-width: 15rem;
    padding: 1.5rem;
    transition: all 0.3s ease;
    box-shadow: 0px 9px 76px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid #eeeeee;
    border-radius: 10px;

    .installments__card__divider {
      background-color: $orangey-yellow !important;
    }

    &__header {
      font-size: 1.25rem;
      line-height: 1.08;
      letter-spacing: 0.2px;

      color: $greyish-brown;
      margin-bottom: 1.6rem;
      font-weight: bold;
    }

    &__text {
      line-height: 1.88;
      letter-spacing: 0.2px;
      color: $greyish-brown-main;
      font-size: 0.9rem;
      height: 40%;

      p {
        width: 100%;
      }
    }
  }

  &__card:hover > .installments__card__header {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) {
  .installments {
    &__card {
      margin: 1.5rem;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .installments {
    &__cardContainer {
      justify-content: left;
    }

    &__card {
      margin: 1rem 1rem 1rem 0;
      width: 11rem;
      box-shadow: none;

      &__header {
        font-weight: normal;
      }

      svg {
        path {
          fill: $white-divider !important;
        }
      }
    }

    &__card:hover {
      box-shadow: 0px 9px 76px 0 rgba(0, 0, 0, 0.08);

      svg {
        path {
          fill: $icon-orange !important;
        }
      }
    }

    .installments__card__divider {
      background-color: $white-divider !important;
    }

    &__card:hover > .installments__card__divider {
      background-color: $orangey-yellow !important;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .installments {
    &__card {
      width: calc(20% - 17px);
      box-sizing: border-box;
    }
  }
}
