.topBarContainer {
  width: 100%;
  margin-top: 60px;
  & > .topBar {
    position: fixed;
    min-height: 60px;
    font-size: 20px;
    top: 0;
    z-index: 100;
  }
}

.topBar-link{
  text-decoration: none;
  font-weight: 400;
}

.topBar__closeButton {
  position: fixed;
  top: 0;
  right: 5px;
  transform: rotate(45deg);
  cursor: pointer;
  z-index: 101;
  color:white
}

@media only screen and (min-width: 1100px) {
  .topBar {
    &__closeButton {
      top: 3px;
      right: 30px;
      color:white;

    }
  }
}
