.codeText {
  &__container {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    margin: 0 1.25rem;
  }
}

@media only screen and (min-width: 768px) {
  .codeText {
    &__container {
      margin: 0 2.5rem;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .codeText {
    &__container {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      margin: auto;
    }
  }
}
