.timeline {
  padding: 2rem 0;
  transition: all 0.3s ease;

  &__flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }

  &__arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 60%;
  }

  &__arrow:hover {
    svg {
      path {
        fill: $icon-orange !important;
      }
    }
  }

  &__nextArrow {
    right: 0;
  }

  &__backArrow {
    padding: 0.2rem;
    left: 0;
  }
}

.timelineItem {
  width: 240px;
  padding: 0 1rem;

  &__number {
    color: $orangey-yellow;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__header {
    color: $greyish-brown-elements;
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__time {
    color: $warm-grey-text;
    font-size: 0.88rem;
    margin-top: 0;
  }

  &__text {
    color: $warm-grey-text;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 768px) {
  .timeline {
    &__nextArrow {
      right: 20px;
    }

    &__backArrow {
      left: 20px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .timelineItem {
    padding: 0 15px 0 0;
    box-sizing: border-box;

    &:last-child {
      padding-right: 0;
    }
  }

  .timeline {
    margin: 0 15px;

    &__flexContainer {
      justify-content: left;
    }
  }
}
