.tilesWithIcons {
  &__container {
    & > h2 {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    flex-direction: column;

    &Alternative {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
  }
}

.tileItem {
  &__icon {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > img {
      max-height: 90px;
      width: auto;
      display: block;
    }

    &--line {
      border-bottom: 2px dotted $secondary;
      width: calc(100% - 115px);
    }
  }
}

@media (min-width: 990px) {
  .tilesWithIcons {
    &__item {
      flex-direction: row;
    }
    &__container {
      padding-top: 20px;
    }
  }

  .tileItem {
    &__icon {
      margin-right: 25px;
    }

    &__content {
      height: 100%;
      flex-direction: column;
      display: flex;

      &--text {

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

@media (min-width: 1240px) {
  .tilesWithIcons {
    &__item {
      width: 25%;

      &--100 {
        width: 100%;
      }

      &--50 {
        width: 50%;

        &:nth-child(odd) {
          padding-right: 40px;
        }

        &:nth-child(even) {
          padding-left: 40px;
        }
      }

      &--33 {
        width: 33%;
      }

      &Alternative {
        width: 50%;
      }
    }

    &__list {
      padding: 0;
    }

    &__container {
      max-width: 1300px;
      margin: 0 auto;
    }
  }
}
