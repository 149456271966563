.article {
  width: 100%;
  margin-bottom: 60px;

  &__picture {
    & > img {
      object-fit: cover;
      display: block;
      width: 100%;
    }
    &--notExist {
      height: 40px;
      background-color: $orangey-yellow;
    }
  }

  &__avatar {
    position: relative;
    top: -30px;
    display: flex;
    align-items: center;
    font-size: 14px;

    & > img {
      vertical-align: middle;
      width: 70px;
      height: 70px;
      padding: 4px;
      border: 1px black dashed;
      border-radius: 50%;
      margin-right: 10px;
    }

    &--publicationInfo {
      font-size: 16px;
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }

    &--publicationInfoWithoutAvatar {
      padding-top: 45px;
    }
  }

  &__content {
    padding: 0 20px 30px 20px;
  }

  &__description {
    img {
      max-width: 100%;
      height: auto;
    }

    &--title {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 26px;
      color: $dark-grey;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 25px;
    }

    &--tags {
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;

      & > a {
        text-transform: uppercase;
        color: $dark-grey;
        text-decoration: none;

        &:after {
          content: "|";
          padding-left: 5px;
          padding-right: 5px;
        }

        &:last-of-type {
          &:after {
            content: "";
            padding: 0;
          }
        }
      }
    }

    &--socialMedia {
      background-color: $light-grey-background;
      padding: 15px 25px;
      display: inline-block;

      & > button {
        margin-right: 15px;
        &:last-of-type {
          margin: 0;
        }
      }
    }
    &--leads {
      font-weight: bold;
      font-size: 24px;
    }

    &--content {
      font-size: 18px;
      line-height: 30px;
      color: $dark-grey;

      a {
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid $dark-grey;

        &:hover {
          color: $dark-blue;
          border-color: $dark-blue;
        }
      }

      p {
        font-weight: lighter;
        font-size: 20px;
      }

      b,
      strong {
        font-weight: 500;
      }

      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
      }

      h1,
      h2 {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .article {
    margin-top: 50px;

    &__picture {
      & > img {
        height: 30vw;
      }
    }

    &__content {
      padding-left: 6.5vw;
      padding-right: 6.5vw;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .article {
    position: relative;

    &__picture {
      & > img {
        height: 20.5vw;
        max-height: 380px;
      }
    }

    &__content {
      padding-left: 160px;
      padding-right: 160px;
    }

    &__description {
      &--title {
        font-size: 36px;
        line-height: 38px;
      }
    }

    &__socialMedia {
      position: absolute;
      right: 0;
      background-color: $light-grey-background;
      box-sizing: border-box;
      width: 150px;
      padding: 15px 10px;
      text-align: center;

      &--label {
        padding-bottom: 35px;
        font-size: 16px;
        color: #2a3030;
        font-weight: 500;
      }

      &--icons {
        padding-bottom: 20px;

        & > button {
          margin-right: 5px;
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }
}
