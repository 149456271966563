.articleItem {
  width: auto;
  margin-bottom: 60px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__picture {
    & > a > img {
      display: block;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &--notExist {
      height: 40px;
      background-color: $orangey-yellow;
    }
  }

  &__avatar {
    position: relative;
    top: -30px;
    display: flex;
    align-items: center;
    font-size: 14px;

    & > img {
      vertical-align: middle;
      width: 60px;
      height: 60px;
      padding: 4px;
      border: 1px black dashed;
      border-radius: 50%;
      margin-right: 10px;
    }

    &--publicationInfo {
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }

    &--publicationInfoWithoutAvatar {
      padding-top: 50px;
    }
  }

  &__content {
    padding: 0 20px 10px 20px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .articleLink {
      color: #2A3030;
      text-decoration: none;
      cursor: pointer;
      border: 2px solid #FABA20;
      font-size: 18px;
      padding: 10px 30px;
      margin: 5px;
      border-radius: 40px;
      display: inline-block;
    }
  }

  &__description {
    position: relative;

    &--title {
      font-size: 22px;
      font-weight: 700;
      margin-top: 0;

      & > a,
      a.articleLink {
        color: $dark-grey !important;
        text-decoration: none;
      }
    }

    &--tags {
      & > a.tag-link {
        text-transform: uppercase;
        color: $dark-grey !important;
        text-decoration: none;
        display: inline-block;

        &:after {
          content: "|";
          padding-left: 5px;
          padding-right: 5px;
        }

        &:last-of-type {
          &:after {
            content: "";
            padding: 0;
          }
        }
      }
    }

    &--content {
      position: relative;
      text-align: justify;
      text-overflow:ellipsis;
      overflow:hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    & > p {
      line-height: 24px;
    }
  }
}

.modernButton {
  text-decoration: none;
  box-shadow: inset 0 0 0 2px $modern-button-shadow;
  padding: 20px 40px;
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  color: $button-grey-text;
  transition: box-shadow 420ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    box-shadow: inset 0 0 0 3px $modern-button-shadow;
    cursor: pointer;
  }
}

@media only screen and (min-width: 768px) {
  .articleItem {
    width: calc(50% - 32px);
    margin-bottom: 30px;

    &__picture {
      & > a > img {
        object-fit: cover;
        height: 25vw;
      }
    }
  }
}

@media only screen and (min-width: 1300px) {
  .articleItem {
    margin-left: 0;
    margin-right: 0;

    &__picture {
      & > a > img {
        height: 17rem;
      }
    }
  }
}
