.basicInput {
  &__label {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;

    &--companyName {
      margin-bottom: 2rem;
    }
  }

  &__input {
    padding: 1.25rem;
    border: none;
    color: $greyish-brown-elements;
    font-weight: bold;
    font-size: 0.9rem;
    width: 100%;
    text-align: left;
    margin: 2px;
    padding-left: 2rem;
    background-color: $white-four;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &__error {
      border: 1px solid red;
    }
  }

  &__input::placeholder {
    text-transform: uppercase;
    color: $greyish-brown-elements;
    font-size: 0.8rem;
    font-weight: bold;
  }

  &__star {
    color: $orangey-yellow;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}
