.courseContact {
  color: $secondary;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;

  &__item {
    margin: 24px 0;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;

    & > img {
      max-width: 60px;
      margin-right: 15px;
    }

    a {
      font-weight: 400;
      font-size: 14px;
      height: 27px;
      line-height: 24px;
    }
  }

  &__addressSection {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__name {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &__additionalInformation {
    p {
      margin: 0;
    }
  }
  div > a {
    color: $light !important;
    text-decoration: none;
    display: flex;
    align-items: center;

    svg {
      fill: $light;
      margin-right: 10px;
    }

    &:hover {
      color: $primary !important;

      svg {
        fill: $primary;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .courseContact {
    &__item {
      width: 50%;
    }
  }
}

@media only screen and (min-width: 1290px) {
  .courseContact {
    padding: 50px 0;

    &__item {
      width: 33%;
      & > img {
        max-width: 80px;
        margin-right: 35px;
      }
    }
  }
}
