.pageNav {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: $white;
  height: 3rem;
  border-top: 1px solid #f5f5f5;
  width: 100%;

  &__link {
    padding: 0.5rem 1rem;
    text-decoration: none;
    flex: 0 0 auto;

    font-size: 0.875rem;
    line-height: 2.44;
    letter-spacing: 0.2px;
    text-align: center;
    color: $brownish-grey-nav;
    text-transform: uppercase;

    &--active {
      font-weight: bold;
      color: $white;
      background-color: $orangey-yellow;
    }
  }

  @keyframes slide {
    100% {
      left: 0;
    }
  }

  .line {
    width: 6rem;
    height: 3px;
    background-color: $orangey-yellow;
    position: absolute;
    align-self: flex-end;
  }

  .link:hover + .line {
    background-color: black;
    animation: slide 1000ms backwards;
  }
}

@media only screen and (min-width: 768px) {
  .pageNav {
    justify-content: center;
  }
}

.scrolling-menu {
  display: flex;
  align-items: center;
}
