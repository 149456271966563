.contactDepartments {
  color: $secondary;
  padding: 25px 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;
  text-align: center;

  &__header {
    font-size: 24px;
    font-weight: 600;
  }

  &__descriptions {
    font-size: 16px;
    margin-bottom: 25px;

    p {
      margin-top: 5px;
    }
  }

  &__itemContainer {
    margin: 24px 0;
    padding: 0 15px;
    width: 100%;
  }

  &__name {
    font-size: 24px;
    font-weight: 500;
  }

  &__office {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 90px 15px 15px 15px;
    box-shadow: 0 0 12px 0 $border;
    border-radius: 10px;
    margin-top: 100px;
    position: relative;

    &:after {
      content: "";
      border-bottom: 1px solid $border;
      position: absolute;
      width: 100%;
      bottom: -30px;
    }
  }

  &__image {
    position: relative;
    & > img {
      display: none;
    }
  }

  &__mobileImage {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 150px;
    border: 1px solid $border;
    box-sizing: border-box;
    position: absolute;
    top: -165px;
    left: -75px;
  }

  &__contact {
    font-size: 20px;

    a {
      text-decoration: none;
      color: $secondary !important;
      &:hover {
        text-decoration: underline;
      }
    }

    path {
      fill: $primary !important;
    }

    &--marginRight {
      margin-right: 5px;
    }
  }
}

@media only screen and (min-width: 990px) {
  .contactDepartments {
    text-align: start;
    padding: 50px 15px;

    &__header {
      font-weight: 500;
    }

    &__descriptions {
      p {
        margin-top: revert;
      }
    }

    &__item {
      flex-direction: row;
      padding: 0;
      box-shadow: none;
      border-radius: unset;
      margin-top: 0;

      &:after {
        display: none;
      }
    }

    &__itemContainer {
      width: 50%;
      padding: 0;
      box-sizing: border-box;

      &:nth-child(odd) {
        padding-right: 60px;
      }

      &:nth-child(even) {
        padding-left: 60px;
      }
    }

    &__office {
      font-size: 14px;
      margin-top: 0;
    }

    &__contact {
      font-size: 14px;
    }

    &__name {
      font-size: 20px;
    }

    &__mobileImage {
      display: none;
    }

    &__image {
      width: 170px;
      & > img {
        display: inline-block;
        width: 100%;
        margin-right: 15px;
      }
    }
  }
}
