.articleBlock {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  &__oneColumn,
  &__twoColumns {
    & > div > div {
      flex: 0 0 100%;
      align-items: center;
      padding: 0 15px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }
  }

  &__twoColumnsRow {
    padding: 20px 0;

    & > .articleBlock > div {
      &:nth-child(even) {
        padding-top: 30px;
        background-color: $background-light;
      }
    }
  }

  &__oneColumn {
    & > div:nth-child(even) {
      background-color: $background-light;
    }

    & > div {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;

      & > .articleBlock__itemContainer {
        max-width: 1270px;
        margin: auto;
      }
    }
  }

  &__text {
    h3 {
      color: $secondary;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    p {
      color: $secondary;
    }
  }

  &__subtitle {
    color: $primary;
    font-weight: 400;
  }

  &__content {
    font-size: 16px;
    font-style: italic;
  }

  &__img {
    position: relative;

    & > img {
      width: 100%;
    }
  }

  &__social {
    position: absolute;
    bottom: 0;
    right: 0;

    filter: grayscale(100%);
    transition: all 0.5s ease;

    &:hover {
      filter: grayscale(0%);
    }
  }

  &__actions {
    a {
      color: $light !important;
      text-decoration: none;
      line-height: 26px;

      &:hover {
        color: $primary !important;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .articleBlock {
    &__oneColumn {
      & > div:nth-child(even) > div {
        flex-direction: row-reverse;

        .articleBlock__text {
          flex: 0 0 60%;
          padding-right: 50px;
          padding-left: 0;
        }
      }
    }

    &__oneColumn {
      & > div > div {
        display: flex;

        .articleBlock__text {
          box-sizing: border-box;
          flex: 0 0 60%;
          padding-left: 50px;
          padding-right: 0;
        }

        .articleBlock__img {
          flex: 0 0 40%;
        }
      }
    }

    &__twoColumnsRow {
      padding: 30px 0;
      &:nth-child(even) {
        background-color: $background-light;
      }

      & > .articleBlock > div {
        &:nth-child(even) {
          background-color: transparent;
          padding-top: 0;
        }
      }

      .articleBlock__itemContainer {
        margin-bottom: 0;
      }
    }

    &__twoColumns {
      justify-content: space-between;

      & > div {
        flex: 0 0 calc(50% - 10px);
        flex-direction: column;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (min-width: 1290px) {
  .articleBlock {
    &__twoColumns {
      & > div > .articleBlock__itemContainer {
        padding: 0;
      }
    }
  }
}
