@import "../BaseColors";

.collectingLeads {
  position: relative;
  &.layout-green {
    .collectingLeads__title {
      color: $light;
    }
  }

  &__container {
    padding: 20px 15px;
    min-height: 460px;

    & > .infoSection {
      & > .collectingLeads__leadContent,
      & > .collectingLeads__title {
        text-align: center;
      }
    }

    & > .collectingLeads__formContainer {
      position: relative;

      & > .plane-img,
      & > .plane-lines-img {
        position: absolute;
        z-index: -1;
        top: 90px;
        height: 240px;
        display: none;
      }

      & > .plane-lines-img {
        right: -170px;
      }

      & > .plane-img {
        left: -160px;
      }

      & > .plane-lines-orangeImg {
        position: absolute;
        z-index: -1;
        display: none;
        left: -170px;
        width: 160%;
      }
    }
  }

  &__backgroundImage {
    position: absolute;
    width: 100% !important;
    height: 460px;
    left: 0;
    z-index: -1;
  }

  &__form {
    padding: 20px;
    border-radius: 25px;
    background-color: $light;
    color: $secondary;
    box-shadow: 0 0 90px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;

    button[type="submit"] {
      width: 100%;
      font-size: 30px;
      font-weight: 500;
      font-family: "Ubuntu", sans-serif;

      &:hover {
        color: $secondary;
      }
    }

    .Select__menu {
      z-index: 3;
    }

    .Select {
      margin-bottom: 20px;
    }

    input,
    .Select__control {
      border: 1px solid $input-border;
      color: $input-text-color;
      border-radius: 25px;
      font-size: 14px;

      &:hover {
        border-color: $primary;
        box-shadow: none;
      }
    }

    .Select.has-value > .Select__control .Select-value {
      padding-left: 25px;
    }

    .Select__value-container {
      padding: 0 8px;

      input {
        margin-bottom: 0;
      }
    }

    .Select--single {
      z-index: 2;
    }

    .Select__control {
      height: 40px;

      .Select-input {
        height: 40px;
      }

      .Select-value,
      .Select-placeholder {
        font-size: 14px;
        color: $input-text-color;
        padding-left: 30px;
        line-height: 40px;
        font-weight: normal;
      }
    }

    textarea,
    input {
      width: 100%;
      box-sizing: border-box;
      padding: 11px 30px;
      transition: ease-in 0.2s;
      margin-bottom: 20px;

      &:focus {
        border-color: $primary !important;
      }

      &.hasError {
        border-color: $error;
      }
    }

    textarea {
      border: 1px solid $input-border;
      color: $input-text-color;
      border-radius: 4px;
      font-size: 12px;
      resize: none;
      height: 75px;
      padding: 10px 20px;
      font-family: "Open Sans", sans-serif;
    }


    .Select.hasError {
      .Select__control {
        border-color: $error;
      }
    }

    .Select-input {
      input {
        border: 0 none;
      }
    }

    label {
      font-size: 16px;
      font-weight: bold;
      color: $secondary;

      p {
        margin: 0;
      }
    }
  }

  &__textAreaContainer {
    margin-bottom: 10px;
    position: relative;

    .text-counter {
      position: absolute;
      font-size: 11px;
      right: 20px;
      bottom: 10px;
    }
  }

  &__contentUnderneath {
    &Info {
      letter-spacing: 0.7px;
      color: $secondary;
      font-size: 0.7rem;
      font-weight: 300;
      overflow: hidden;
      transition: all 0.1s;
      margin-top: 0;

      p {
        margin-top: 0;
        line-height: 15px;
      }

      &--notActive {
        max-height: 30px;
      }

      &--active {
        max-height: 500px;
      }
    }

    &ShowMore {
      color: $black;
      margin-bottom: 20px;
      font-size: 11px;
      cursor: pointer;
    }
  }

  &__label {
    margin-bottom: 11px;

    span.hasError {
      color: $error;
      font-size: 11px;
      font-weight: normal;
      padding-left: 10px;
    }
  }

  &__checkbox {

    label {
      display: flex;
      position: relative;
      cursor: pointer;
      user-select: none;
      margin-bottom: 20px;

      span.customCheck {
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        border: 1px solid $black;

        &.hasError {
          border-color: $error;
        }

        &:after {
          content: "";
          position: absolute;
          left: 3px;
          top: -1px;
          width: 5px;
          height: 10px;
          border: solid $black;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          opacity: 0;
        }

        &--checked {
          &:after {
            opacity: 1;
          }
        }
      }


      & > div {
        color: $secondary;
        font-size: 12px;
        font-weight: 400;

        &.hasError {
          color: $error;
        }
      }
    }

    input[type="checkbox"] {
      width: auto;
      opacity: 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .collectingLeads {
    &__container {
      display: flex;
      padding: 40px 15px;

      & > .infoSection {
        & > .collectingLeads__leadContent,
        & > .collectingLeads__title {
          text-align: start;
        }
      }

      & > .infoSection {
        width: 50%;
        padding-right: 40px;
        box-sizing: border-box;
      }

      & > .collectingLeads__formContainer {
        width: 450px;
      }
    }

    &__form {
      padding: 20px 35px;
    }
  }
}

@media only screen and (min-width: 1290px) {
  .collectingLeads {
    &__container {
      & > .infoSection {
        padding-right: 80px;

        & > .collectingLeads__leadContent,
        & > .collectingLeads__title {
          max-width: 435px;
        }
      }

      & > .collectingLeads__formContainer {
        width: 540px;

        & > .plane-img,
        & > .plane-lines-orangeImg,
        & > .plane-lines-img {
          display: block;
        }
      }
    }
  }
}