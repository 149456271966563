.movie {
  &__container {
    margin: 0 1.25rem;

    iframe {
      display: inherit;
      margin: auto;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .movie {
    &__container {
      margin: auto;

      iframe {
        max-width: 600px;
      }
    }
  }
}
