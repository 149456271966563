.tile2Columns {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__card {
    width: 100%;
    margin: 2rem;
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 15px 70px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid $light-grey;

    &__img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .tile2Columns {
    &__container {
      padding: 0 2.5rem;
    }

    &__card {
      padding: 4rem 2rem;
      width: calc(50% - 4rem);

      &:nth-child(odd) {
        margin-left: 0;
      }

      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .tile2Columns {
    &__card {
      box-shadow: none;
      border: 1px solid transparent;
    }

    &__card:hover {
      box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.05);
      border-color: $light-grey;
    }
  }
}
