.productListingGrey {
  background-color: $background-light;
  padding: 10px 0;
}

.productListingWhite {
  background-color: $light;
  padding: 10px 0;
}

.productListing {
  &__container {
    margin: 40px auto;
    padding: 0 10px;
  }

  &__empty {
    text-align: center;

    .terms-label {
      font-size: 23px;
      margin-bottom: 1rem;
      color: $orange-link-background;
    }

    .contact-label {
      font-size: 25px;
      margin-bottom: 2rem;
    }
  }

  &__dataLabels {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: $additional-text;

    &--round {
      height: 30px;
      margin-bottom: 5px;
    }
  }

  &__dataLabels {
    display: none;
    visibility: hidden;
  }

  &__dataSeries {
    display: flex;
    flex-direction: column;
  }

  &__listRow {
    border: 1px solid $border;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: $secondary;
    padding: 20px 10px;

    &--basic {
      border: none;
      padding: 0 10px;
    }

    & > div:first-child {
      font-weight: 700;
    }

    & > div.withIcon {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      & > img {
        width: 40px;
        margin-right: 10px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actionButtonsContainer {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      & > a {
        display: block;
      }
    }
  }

  &__prices {
    &--small {
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__actionButtons {
    width: 100%;
    a {
      color: $light !important;
      text-decoration: none;
      text-align: center;
      line-height: 46px;
      height: 48px;
      min-width: 130px;

      &:hover {
        color: $primary !important;
      }

      &.productListing__actionButtons--link {
        color: $primary !important;
        display: block;
        font-size: 16px;
        font-weight: 400;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  &__mobile {
    border: 1px solid $border;
    border-radius: 5px;
    padding: 15px 0;
    margin: 10px 10px 30px;
    position: relative;
    background: $light;

    &:last-of-type {
      margin-bottom: 10px;
    }

    &--details {
      display: flex;
      padding-top: 15px;
      border-bottom: 1px solid $border;

      & > div {
        width: 50%;

        &:nth-child(1) {
          border-right: 1px solid $border;
        }
      }
    }

    &--label {
      background-color: $background-light;
      border-bottom: 1px solid $border;
      color: $additional-text;
      padding: 5px 15px;
      font-size: 12px;
    }

    &--actions {
      text-align: center;
      border-top: 1px solid $border;
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        line-height: 42px;

        &.productListing__actionButtons--link {
          color: $primary !important;
          margin-left: 15px;
        }
      }
    }

    &--data {
      padding: 5px 15px;
      font-size: 14px;
    }

    &--card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
    }

    .smallTitle {
      position: absolute;
      font-size: 12px;
      font-weight: 500;
      top: -10px;
      background-color: $light;
      border-radius: 10px;
      padding: 0 5px;
      border: 1px solid $border;

      span {
        z-index: 3;
        padding: 0 2px;
        opacity: 0.7;
      }
    }

    .collapse-icon {
      transition: all 0.2s ease-in-out;
    }

    .withIcon {
      display: flex;
      align-items: center;
      font-weight: 500;

      & > img {
        margin-right: 15px;
        width: 60px;
      }
    }
  }
}

@media (min-width: 990px) {
  .productListing {
    &__dataLabels,
    &__dataSeries {
      align-items: center;
      flex-direction: row;

      & > div {
        box-sizing: border-box;
        width: 12.5%;

        &:first-of-type {
          width: 25%;
        }

        &:last-of-type {
          width: 25%;
          padding-left: 10px;
          padding-right: 20px;
        }
      }
    }
    &__dataLabels--basic {
      & > div {
        &:first-of-type {
          padding-left: 0;
        }
      }
    }
    &__dataLabels--basic,
    &__listRow--basic {
      & > div {
        &:first-of-type {
          width: 12%;
        }
        width: 12%;

        &:nth-child(5) {
          width: 10%;
        }

        &:last-of-type {
          width: 42%;
        }
      }
    }

    &__dataLabels {
      display: flex;
      visibility: visible;
      padding: 0 20px;

      &--round {
        background-color: $background-light;
        border-radius: 15px;
        margin-bottom: 20px;
      }

      & > div {
        padding-left: 20px;

        &:last-of-type {
          padding-left: 30px;
        }

        .firstLabel {
          padding-left: 75px;
        }
      }
    }

    &__dataSeries {
      & > div {
        padding-left: 10px;

        &:before {
          content: "";
          height: 19px;
          border-right: 3px solid $border;
          margin-right: 15px;
        }

        &:first-of-type:before {
          display: none;
        }
      }
    }

    &__listRow > div.withIcon {
      margin-bottom: 0;
    }

    &__actionButtonsContainer {
      margin-top: 0;

      & > div {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

@media (min-width: 1290px) {
  .productListing {
    &__container {
      width: 1270px;
      margin: 20px auto;
      padding: 0;
    }
  }
}
