.linkComponent {
  &__container {
    display: flex;
  }

  &__item {
    margin-left: auto;
    margin-right: 1.25rem;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.63;
    color: $greyish-brown;
    font-size: 0.9rem;
    text-transform: uppercase;
    transition: all 0.3s ease;
    text-decoration: none;
    font-weight: normal;
  }

  &__icon {
    margin-left: 1rem;
  }

  &__item:hover {
    color: $orangey-yellow;

    svg {
      path {
        fill: $icon-orange !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .linkComponent {
    &__item {
      margin-right: 2.5rem;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .linkComponent {
    &__item {
      margin-right: 0rem;
    }
  }
}
