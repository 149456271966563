.playlist {
  display: flex;
  align-items: center;
  justify-content: center;

  &--vertical {
    @media only screen and (min-width: 374px) {
      flex-direction: column;
    }
  }

  &__container {
    width: 55vw;
    max-width: 600px;
    margin: 0 5vw;

    @media only screen and (min-width: 768px) {
      width: 82vw;
    }

    &--vertical {
      width: 25vw;
      max-width: 400px;

      @media only screen and (min-width: 768px) {
        width: 25vw;
      }
    }
  }

  &__previous {
    cursor: pointer;
    transform: rotate(180deg);
    filter: grayscale(100%);
    width: 5vw;
    @media only screen and (min-width: 768px) {
      width: 30px;
    }
    &:hover {
      filter: grayscale(0%);
    }
  }

  &__next {
    cursor: pointer;
    filter: grayscale(100%);
    width: 5vw;
    @media only screen and (min-width: 768px) {
      width: 30px;
    }
    &:hover {
      filter: grayscale(0%);
    }
  }

  &__picture-container {
    position: relative;
  }

  &__video {
    cursor: auto;
    background-color: $white;
    transition: all 0.5s ease;
    &:hover {
      -webkit-box-shadow: 0 -1px 10px 3px rgba(0, 0, 0, 0.13);
      box-shadow: 0 -1px 10px 3px rgba(0, 0, 0, 0.13);
    }
    &--vertical {
      display: flex;
      min-height: 12.5vw;
    }
  }

  &__picture {
    width: 99.9%;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      width: 99.7%;
    }

    &--vertical {
      clip: rect(0px, 60px, 201px, 0px);
    }

    &--play {
      border: 3px solid #fbbb21;
      width: 6vw;
      height: 6vw;
      max-width: 100px;
      max-height: 100px;
      position: absolute;
      z-index: 6;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        background: rgba(0, 0, 0, 0.45);
      }
      @media only screen and (min-width: 768px) {
        border: 3px solid #fbbb21;
        width: 4vw;
        height: 4vw;
      }
      &-button {
        color: #fbbb21;
        max-width: 3vw;
        @media only screen and (min-width: 768px) {
          max-width: 1.5vw;
        }
      }
    }

    &__icon {
      position: absolute;
      right: 0.5px;
      bottom: 0;
      img {
        max-height: 40px;
        max-width: 40px;
      }
    }
  }

  &__text-box {
    min-height: 112px;
    text-align: left;
    div {
      border: 2px solid #fbbb21;
      margin-left: 10px;
      margin-right: 10px;
    }

    img {
      max-width: 30px;
      margin-left: 10px;
      transform: translateY(3px);
    }

    &__title {
      margin: 5px 10px;
      font-size: 15px;
      font-weight: bold;
      padding-bottom: 0.3rem;
    }

    &__text {
      margin: 5px 10px;
      font-size: 11px;
      p {
        margin-bottom: 1px;
      }
    }

    a.playlist__text-box__text {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      text-decoration: none;
      color: #4c4c4c !important;
      margin-top: 0;
      &:hover {
        color: #fbbb21 !important;
      }

      p {
        transform: translateY(1px);
      }

      img {
        margin-top: 0;
        margin-bottom: 0;
        filter: grayscale(100%);
        &:hover {
          filter: grayscale(0%);
        }
      }
    }
  }
}

.slider-list,
.slider-frame {
  min-height: 268px;

  @media only screen and (min-width: 374px) {
    min-height: 280px;
  }

  @media only screen and (min-width: 900px) {
    min-height: 302px;
  }

  @media only screen and (min-width: 750px) and (min-height: 1000px) {
    min-height: 307px;
  }
}

.slider {
  visibility: visible !important;
}

.v-playlist {
  width: 350px;
  text-align: center;
  min-width: 325px;
  padding-top: 4px;
  padding-right: 1rem;
  &__video {
    display: flex;
    background-color: $white;
    transition: all 0.5s ease;
    &:hover {
      -webkit-box-shadow: 0 -1px 10px 3px rgba(0, 0, 0, 0.13);
      box-shadow: 0 -1px 10px 3px rgba(0, 0, 0, 0.13);
    }
  }

  &__video--with-border {
    border: 0.5px solid #d0d0d0;
    transition: all 0.5s ease;
    &:hover {
      -webkit-box-shadow: 0 -1px 10px 3px rgba(0, 0, 0, 0.13);
      box-shadow: 0 -1px 10px 3px rgba(0, 0, 0, 0.13);
    }
  }

  &__picture-container {
    width: 45%;
    overflow: hidden;
    position: relative;
  }

  &__picture-box {
    img:first-of-type {
      width: 290px;
      transform: translate(-35%);
      display: block;
    }
  }

  &__picture__icon {
    right: 0;
    bottom: 0.5px;
    &__grey-bg {
      bottom: 0;
    }
  }

  &__picture__icon img {
    display: block;
  }

  &__previous {
    cursor: pointer;
    transform: rotate(-90deg);
    filter: grayscale(100%);
    width: 30px;
    &:hover {
      filter: grayscale(0%);
    }
  }

  &__next {
    cursor: pointer;
    filter: grayscale(100%);
    transform: rotate(90deg);
    width: 30px;
    &:hover {
      filter: grayscale(0%);
    }
  }

  &__text-box {
    width: 54%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    &__text {
      align-self: flex-end;
    }
  }
}
.carousel {
  padding-top: 4px;
}

.carousel__next-button,
.carousel__back-button {
  background: transparent;
  border: none;
  outline: none;
}

.playlist__hide {
  visibility: hidden;
}
