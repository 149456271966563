@import "colors.scss";
@import "./BaseColors.scss";
@import "pages/NotFound";
@import "pages/ServerError";
@import "pages/DataForm";
@import "components/BasicButton";
@import "blocks/MainHeader";
@import "components/Icon";
@import "components/TopBar";
@import "components/PageNav";
@import "blocks/CollectingLeads";
@import "components/PhoneNumberWithPrefix";
@import "components/Footer";
@import "components/Header";
@import "components/Divider";
@import "components/Waves";
@import "components/Pages";
@import "components/Story";
@import "components/Loader";
@import "components/CookiesConfirmation";
@import "components/FormsySelect";
@import "components/BasicInput";
@import "components/Address";
@import "components/FormsyCheckbox";
@import "components/Playlist";
@import "components/CurrentMovie";
@import "components/Article";
@import "blocks/MainSlider";
@import "blocks/Modes";
@import "blocks/Counters";
@import "blocks/TilesInColumns";
@import "blocks/Team";
@import "blocks/Accordions";
@import "blocks/Advantages";
@import "blocks/Anchor";
@import "blocks/ProductSlider";
@import "blocks/TilesWithIcons";
@import "blocks/ListWithBullets";
@import "blocks/Questions";
@import "blocks/TwoColumns";
@import "blocks/Movie";
@import "blocks/Movies";
@import "blocks/ProductFilteredListing";
@import "blocks/PayU";
@import "blocks/Timeline";
@import "blocks/Slider";
@import "blocks/Button";
@import "blocks/Pricing";
@import "blocks/SliderWithTabs";
@import "blocks/ScheduleBlock";
@import "blocks/ArticleItem";
@import "blocks/ArticlesList";
@import "blocks/ArticleBlock";
@import "blocks/ArticleGroupBlock";
@import "blocks/Contact";
@import "blocks/ImageBlock";
@import "blocks/Installments";
@import "blocks/ContentInTile";
@import "blocks/CodeText";
@import "blocks/TextWithMap";
@import "blocks/Expectations";
@import "blocks/Testimonial";
@import "blocks/Numbered4Columns";
@import "blocks/Link";
@import "blocks/Tile2Columns";
@import "blocks/Mentors";
@import "blocks/TwoColumnsSlider";
@import "blocks/StudentStories";
@import "blocks/PartnerLogos";
@import "blocks/GraduateProjects";
@import "blocks/CoursesContact";
@import "blocks/ContactCa";
@import "blocks/ContactDepartments";
@import "blocks/TwoColumnsMovie";
@import "blocks/GoToShop";
@import "blocks/CoursesList";
@import "blocks/RelatedPosts";
@import "blocks/GroupedTimeline";
@import "blocks/ExpandableTileCases";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popupLeadsModal {
  position: absolute;
  width: 80%;
  max-width: 370px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0px 26px 79px 0 rgba(0, 0, 0, 0.13);
  border-radius: 11px;
  transition: all 150ms ease;
  max-height: 520px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0;
  padding: 1rem;
  padding-bottom: 2rem;
}

.popupExitModal {
  padding: 2rem;
  position: absolute;
  top: 0%;
  background-color: $greyish-brown-main;
  transition: all 150ms ease;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0;
  left: 50%;
  transform: translate(-50%);
}

.popupExitModalSubmit {
  top: 20%;
}

@media only screen and (min-width: 1200px) {
  .popupLeadsModal {
    top: 50%;
    left: 50%;
    padding: 1rem 5rem 3rem 5rem;
    max-width: 930px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1440px) {
  .popupLeadsModal {
    top: 50%;
    left: 50%;
    padding: 1rem 8rem 3rem 8rem;
    max-width: 930px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1290px) {
  .desktop-container {
    width: 1270px;
    margin: 0 auto;
  }

  .Toastify__toast-container--top-center {
    top: 120px !important;
  }
}

@media only screen and (max-width: 767px) {
  iframe#usercom-launcher-dot-frame[name] {
    width: 40px !important;
    height: 50px !important;
    bottom: 8px !important;
    z-index: 1001 !important;
  }

  iframe#usercom-launcher-dot-frame[name],
  #usercom-greeting-wrapper-frame {
    right: auto !important;
  }
}

@media only screen and (min-width: 551px) and (max-width: 767px) {
  #usercom-board-frame {
    right: auto !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7c7c7d;
}

::-webkit-scrollbar-thumb {
  background: $border;
}

::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

.modern-button {
  border-radius: 50px;
  background-color: transparent;
  outline: none;
  border: 2px solid transparent;
  font-size: 16px;
  font-weight: 600;
  padding: 0 28px;
  height: 50px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: $secondary;
  box-sizing: border-box;
  display: inline-block;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 24px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s;
    border-radius: 24px;
    z-index: -1;
  }

  svg {
    fill: $secondary;
  }

  &:hover {
    color: $primary;
    cursor: pointer;

    svg {
      fill: $primary;
    }
    &:before {
      width: 100%;
    }
  }

  &-primary {
    background-color: $primary;
    border-color: $primary;
    color: $light;

    & > svg {
      fill: $light;
    }

    &:after {
      background-color: $primary;
    }
    &:before {
      background-color: $light;
    }
    &:hover {
      color: $primary;
      background-color: $light;

      & > svg {
        fill: $primary;
      }
    }
  }

  &-primary-outline {
    background-color: $light;
    border-color: $primary;
    color: $secondary;

    & > svg {
      fill: $secondary;
    }

    &:after {
      background-color: $light;
    }
    &:before {
      background-color: $primary;
    }
    &:hover {
      color: $light;
      background-color: $primary;

      & > svg {
        fill: $light;
      }
    }
  }

  &-secondary {
    background-color: $secondary;
    border-color: $secondary;
    color: $light;

    & > svg {
      fill: $light;
    }

    &:after {
      background-color: $secondary;
    }
    &:before {
      background-color: $light;
    }
    &:hover {
      color: $secondary;
      background-color: $light;

      & > svg {
        fill: $secondary;
      }
    }
  }

  &-small {
    padding: 0 20px;
    height: 31px;
    border-radius: 40px;
  }

  &-outline-transparent {
    background-color: $primary;
    border-color: $primary;
    color: $secondary;

    & > svg {
      fill: $secondary;
    }

    &:after {
      background-color: $light;
    }
    &:before {
      background-color: $secondary;
    }
    &:hover {
      color: $light;
      background-color: $secondary;

      & > svg {
        fill: $light;
      }
    }
  }

  &-outline-secondary {
    background-color: $primary;
    border-color: $secondary;
    color: $secondary;

    & > svg {
      fill: $secondary;
    }

    &:after {
      background-color: $primary;
    }
    &:before {
      background-color: $secondary;
    }
    &:hover {
      color: $light;
      background-color: $secondary;

      &:after {
        transition: background-color 0s linear 0.3s;
        background-color: $secondary;
      }

      & > svg {
        fill: $light;
      }
    }
  }
}

.modern-list {
  list-style: none;

  li::before {
    content: "\25E6";
    color: $primary;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.modern-list-white > li::before {
  color: $light;
}
