.contentInTile {
  margin-left: 15px;
  margin-right: 15px;

  &__tile {
    background-color: $white;
    margin: 2rem auto;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    position: relative;
    padding: 3rem 2rem;
  }

  &__img--bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120px;
  }

  &__img--top {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(180deg);
    width: 120px;
  }

  &__text {
    padding: 0 1.25rem;
    letter-spacing: normal;

    color: $brownish-grey-nav;
    font-size: 0.8rem;
    max-width: 65rem;
  }
}

@media only screen and (min-width: 768px) {
  .contentInTile {
    &__tile {
      margin: 0.625rem auto;
      padding: 3rem 6rem;

      &__img--bottom {
        width: 200px;
      }

      &__img--top {
        width: 200px;
      }
    }

    &__text {
      padding: 0 2.5rem;
      max-width: 65rem;
      margin-top: 1rem;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .contentInTile {
    &__text {
      padding: 1.25rem 2.5rem;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .contentInTile {
    &__tile {
      max-width: 65rem;
      margin: 2rem auto;
    }
  }
}
