@import "../colors.scss";

.BasicButton {
  position: relative;
  width: 158px;
  height: 45px;
  mix-blend-mode: undefined;
  border-radius: 22.5px;
  background-color: $orangey-yellow;
  box-shadow: 0px 11px 27px 0 rgba(0, 0, 0, 0.07);
  border: none;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  outline: 0;
}

.BasicButton__label {
  text-transform: uppercase;
  mix-blend-mode: undefined;
  font-size: 16.5px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: $white;
}
