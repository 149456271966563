.articleGroupBlockComponent {
  &__container {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;

    &__flex {
      display: flex;
      flex-flow: column;
      padding: 1rem;
    }

    &__img {
      max-width: 270px;
    }

    &__link {
      text-decoration: none !important;
    }

    &--dark {
      color: $secondary;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 5px;
    }

    &--light {
      color: $additional-text;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
    }
  }

  &__scrollingMenu {
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    max-width: 1200px;
    position: relative;

    .arrow {
      top: calc(50% - 50px);
      transition: all 0.25s ease-in;
      position: absolute;
      z-index: 2;
      cursor: pointer;

      &__right {
        right: -18px;
        transform: scaleX(-1);
      }

      &__left {
        left: -18px;
      }

      &__desktop {
        display: none;
      }

      &__mobile {
        display: inline-block;
      }

      & > span > svg {
        transform: translateX(20px);
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .articleGroupBlockComponent {
    &__container {
      &__flex {
        padding: 1rem 1.5rem;
      }

      &__img {
        max-width: calc(100% - 3rem);
      }
    }
  }
  .ButtonBlockComponent {
    a {
      font-size: 0.85rem;
      padding: 0 2rem;
      margin: 1rem 0;
    }
  }
}

@media only screen and (max-width: 790px) {
  .articleGroupBlockComponent__container {
    overflow-x: scroll;
  }
}

@media only screen and (min-width: 1024px) {
  .ButtonBlockComponent {
    max-width: 67rem;
  }
}

@media (min-width: 768px) {
  .articleGroupBlockComponent__scrollingMenu {
    .arrow {
      &__mobile {
        display: none;
      }

      &__desktop {
        display: inline-block;
      }

      &__right {
        right: -50px;
      }

      &__left {
        left: -50px;
      }

      &:hover {
        & > span > svg {
          transform: translateX(5px);
        }
      }

      &:after {
        content: "";
        height: 35px;
        width: 35px;
        position: relative;
        border: 2px solid $primary;
        border-radius: 100%;
        top: -31px;
        display: block;
        z-index: -1;
      }
    }
  }
}
