.testimonial {
  margin: 20px auto;
  padding: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/blocks/Testimonial/background.webp");

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0;
  }

  &__content {
    font-size: 24px;
  }

  &__authorInfo {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;

    & > div {
      border-top: 1px solid $black;
      padding-top: 15px;

      &:first-child {
        font-weight: bold;
        padding-left: 25px;
      }
    }
  }
}

@media (min-width: 768px) {
  .testimonial {
    margin: 50px auto;

    &__container {
      width: 600px;
      margin: 0 auto;
    }

    &__authorInfo {
      margin-right: 10px;
    }
  }
}

@media (min-width: 1200px) {
  .testimonial {
    &__container {
      width: 820px;
      padding-left: 100px;
    }

    &__authorInfo {
      margin-right: 150px;
    }
  }
}
