.ButtonBlockComponent {
  margin: 0 auto;

  a,
  span {
    font-size: 1.1rem;
    font-weight: 700;
    text-decoration: none;
    color: $orangey-yellow;
    line-height: 48px;
    padding: 0 2.4rem;
    margin: 1.3rem;
    border: 3px solid $orangey-yellow;
    border-radius: 30px;
    transition: all 0.3s ease;
    &:hover {
      color: $white !important;
      background-color: $orangey-yellow;
    }
  }
}

@media only screen and (max-width: 430px) {
  .ButtonBlockComponent {
    a,
    span {
      font-size: 0.85rem;
      padding: 0 2rem;
      margin: 1rem 0;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .ButtonBlockComponent {
    max-width: 67rem;
  }
}
