.scheduleContainer {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  &__title {
    text-align: center;
    padding: 0 15px;
  }

  &__timeline {
    margin-top: 15px;
  }
}

div.vertical-timeline {
  padding-bottom: 0;

  &:before {
    background: $secondary;
    left: 19px;
    width: 2px;
  }
}

.scheduleTimeLine {
  &__date {
    opacity: 1 !important;
  }

  &__item {
    padding: 0 10px;
  }

  &__actions {
    text-align: center;

    &Mobile {
      padding-top: 20px;
    }

    & > button {
      font-weight: normal;
      font-size: 14px;
      background-color: transparent;

      &::before {
        background-color: $primary;
      }

      &::after {
        background-color: transparent;
      }

      &:hover {
        background-color: $primary;
      }
    }
  }

  &__itemName {
    color: $dark-grey;
    background-color: $timeline-item-background;
    border-radius: 50px;
    text-transform: uppercase;
    padding: 10px 15px;
    display: block;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1170px) {
  .scheduleContainer {
    &__timeline {
      margin-top: 30px;
    }

    div.vertical-timeline {
      &:before {
        width: 0;
        border-right: 3px dashed $secondary;
        background: transparent;
      }
    }
  }

  .scheduleTimeLine {
    &__itemName {
      padding: 15px 30px;
      display: inline;
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 2em 0;
  }
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  &:last-child {
    padding-bottom: 0;
  }
}
