.twoColumnsMovie {
  background-color: $orangey-yellow;

  &__linkWhite {
    width: 100%;
    padding-bottom: 0rem;

    .linkComponent__item {
      color: $white !important;

      svg {
        path {
          fill: $white !important;
        }
      }
    }
  }

  &__rightContainer {
    height: 100%;
  }

  &__movieContainer {
    margin: 0 1.25rem;
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .twoColumnsMovie {
    &__container {
      display: flex;
      flex-wrap: wrap;
    }

    &__leftContainer {
      flex: 0 0 50%;
    }

    &__rightContainer {
      padding-top: 5.25rem;
      flex: 0 0 50%;
      margin: auto;
      margin-top: 0;
    }

    &__movieContainer {
      max-width: 640px;
    }

    &__linkWhite {
      text-align: right;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .twoColumnsMovie {
    &__rightContainer {
    }
  }
}

@media only screen and (min-width: 1440px) {
  .twoColumnsMovie {
    &__linkWhite {
      .linkComponent__item {
        margin-left: 0rem !important;
      }
    }

    &__leftContainer {
      width: 35%;
    }

    &__rightContainer {
      width: 60%;
    }

    &__header {
      padding-left: 0 !important;
    }

    &__container {
      grid-template-columns: 1fr 2fr;
    }
  }
}
