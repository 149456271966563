.countersBlock {
  background-color: $background-light;
  text-align: center;
  padding: 20px 0;
  background-size: cover;

}

.countersTitle {
  font-size: 1em;
}

.countersContainer {
  color: $secondary;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 190px;
  box-sizing: border-box;
  flex-direction: column;

  &__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &--counter {
      text-align: center;
      margin: 20px 0;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      box-sizing: border-box;

      &:last-of-type {
        margin-right: 0;
      }

      & > .counterNumber {
        font-size: 1em;
      }

      & > .counterLabel {
        font-size: 16px;
        margin: 0 10px;
        line-height: 1.1;
      }
    }
  }
}

@media (min-width: 768px) {

  .countersTitle {
    font-size: 1.38em;
  }
  .countersContainer {
    flex-direction: row;

    &__label {
      text-align: left;
    }

    &__items {
      justify-content: space-between;
      flex-direction: row;

      &--counter {
        width: 25%;

        & > .counterNumber {
          font-size: 1.38em;
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .countersContainer {
    padding: 20px 0;
  }
}
