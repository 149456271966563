@import "../colors";
@import "../BaseColors";

.main-slider {
  &__desktop {
    & > .carousel {
      padding-top: 0;

      @media (max-width: 768px) {
         display: none;
      }

      @media (min-width: 768px) and (max-width: 1290px) {
        padding-top: 50px;
      }

      .slider-wrapper {
        max-height: 670px;

        ul.slider {
          max-height: 670px;
        }
      }
    }
  }

  &__mobile {
    & > .carousel {
      padding-top: 0;
      .control-dots .dot {
        opacity: 1;
        box-shadow: none;
        border: 1px solid $light;
        background-color: transparent;
        margin: 2px;
        width: 6px;
        height: 6px;

        &.selected {
          background-color: rgba(249, 185, 32, 0.4);
        }
      }
    }

    .slider > .slide {
      height: calc(100vh - 116px);

      & > .main-slider__slide {
        height: 100%;
        background-size: cover;
      }
    }
  }

  &__slide {
    .cards {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -45%;
      width: 90%;

      &Container {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @media (min-width: 768px) {
          width: calc(100% - 240px);
          margin: 0 auto;
          padding: 0;
        }

        @media (min-width: 1480px) {
          width: 1270px;
        }
      }
    }

    .slide__content {
      left: 50%;
      margin-left: -45%;
      width: 90%;
      position: absolute;
      top: 0;
      bottom: 0;

      &Container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        padding: 0 15px;

        @media (min-width: 768px) and (max-width: 1199px) {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }

        h1 {
          margin-bottom: 0;

          @media (min-width: 768px) and (max-width: 1290px) {
            font-size: 3vw;
          }
        }

        & > div {
          width: 100%;
          font-size: 16px;
          text-align: left;
          line-height: 24px;

          @media (min-width: 768px) and (max-width: 1290px) {
            font-size: 1.5vw;
          }
        }

        .main-slider-partner-logo {
          width: 180px;
          @media (min-width: 768px) and (max-width: 1290px) {
            width: 12vw;
          }
        }

        &--mobile {
          h1 {
            margin-bottom: 0;
          }

          p {
            margin-top: 5px;
          }

          .main-slider-partner-logo {
            width: 130px;
          }
        }

        @media (min-width: 768px) {
          width: calc(100% - 240px);
          height: 100%;
          margin: 0 auto;
          padding: 0;
        }

        @media (min-width: 1480px) {
          width: 1270px;
        }
      }

      &--mobile {
        width: auto;
        left: 0;
        margin: 0;
        text-align: center;
        padding: 25px;
        display: flex;
      }
    }

    .slider-card {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      background-color: $light;
      box-shadow: 0px -13px 20px #00000029;
      opacity: 0.89;
      width: 50%;
      transition: all 0.2s ease-in-out;
      color: $secondary;
      text-align: left;
      padding: 20px;
      overflow: hidden;
      height: 115px;
      display: none;

      @media (min-width: 1200px) {
        display: block;
      }

      &:nth-child(2) {
        margin-left: 15px;
      }

      &--active {
        background-color: $primary;
        opacity: 1;
        height: 170px;
      }

      &__title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & > h2 {
          margin: 0;
          font-size: 24px;
          font-weight: 600;
        }

        & > img {
          height: 50px;
          width: auto!important;
          margin-right: 20px;
        }
      }

      &__withoutImg {
        margin: 15px 0;
      }

      &__description {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 20px;
      }

      &__action {
        padding-bottom: 20px;
        box-sizing: border-box;

        & > a {
          display: flex;
          max-width: 150px;
          color: $secondary;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          &:hover {
            color: $light;
          }

          & > svg {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.slider-control-arrow-button {
  top: 0;
  bottom: 0;
  transition: all 0.25s ease-in;
  position: absolute;
  z-index: 2;
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  &.disabled {
    display: none;
  }

  &:hover {
    & > svg {
      transform: translateX(0);
    }
  }

  &:after {
    content: "";
    height: 35px;
    width: 35px;
    position: relative;
    border: 2px solid $primary;
    border-radius: 100%;
    top: -30px;
    display: block;
  }

  &.next {
    right: 35px;
    transform: scaleX(-1);
  }

  &.prev {
    left: 35px;
  }

  & > svg {
    fill: $light;
    transform: translateX(14px);
    transition: all 0.4s ease-in-out;
  }
}

.slide-fadeIn_animation {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-fadeOut_animation {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
