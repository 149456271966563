.dataForm {
  a {
    color: $orangey-yellow !important;
  }

  &__header {
    width: 100%;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1.2rem 0 1.2rem 0;
    font-size: 1.2rem;
    padding: 0 0.5rem;
  }

  &__form {
    max-width: 42rem;
    margin: auto;
    padding: 0 0.5rem;

    .formsySelect {
      margin-top: 1.5rem;
      margin-bottom: 0;
    }
    .basicInput__star {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

      .Select__control {
        border: none;
        color: $greyish-brown-elements;
        font-size: 0.9rem;
        width: 100%;
        text-align: left;
        margin: 2px;
        padding-left: 2rem;
        background-color: $white-four;
      }

      .Select__placeholder {
        padding: 0.9rem;
      }

      .Select__value {
        padding: 0.9rem;
        color: $greyish-brown-elements;
        font-weight: bold;
        font-size: 0.9rem;
      }

    &__companyName {
      margin-bottom: 2rem;
    }

    &__reCaptcha {
      width: 19rem;
      margin: auto;
      margin-bottom: 2rem;
    }
  }

  &__btn {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }

  &__addressContainer {
    display: flex;
    flex-wrap: wrap;

    &__country {
      width: 10% !important;

      .basicInput__input {
        padding-left: 1rem !important;
      }
    }

    &__bankAccount {
      width: 90% !important;
    }
  }

  &__text {
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.thankYou {
  padding: 1.25rem;
  max-width: 21rem;
  margin: auto;

  &__img {
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
  }


  .centeredHeader {
    padding: 1.25rem;

    &__header {
      font-size: 1.3rem;
      letter-spacing: 0.4px;
      text-align: center;
      color: $greyish-brown-main;
      margin-top: 0.3rem;
      margin-bottom: 1.3rem;
      font-weight: normal;
    }

    &__info {
      font-size: 1rem;
      color: $brownish-grey-nav;
      margin-top: 0;
      text-align: center;
    }
  }

  @media only screen and (min-width: 1024px) {
    .centeredHeader {
      &__desktopWrapper {
        max-width: 67rem;
        margin: auto;
      }

      &__header {
        margin-top: 1.4rem;
        margin-bottom: 2rem;
        font-size: 1.6rem;
      }

      &__info {
        margin-bottom: 2.2rem;
        font-size: 0.875rem;
      }
    }
  }
}

