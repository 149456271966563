.mentors {
  margin: 0 15px;

  &__container {
    position: relative;
  }

  &__mentor {
    position: relative;
    padding: 3rem 1rem;
    background-color: $white;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    margin: auto;

    & > hr {
      margin-top: 1.5rem;
      width: 220px;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #eee;
      border-bottom: none;
    }

    &__wave_blue--left {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 70px;
    }

    &__wave_blue--right {
      position: absolute;
      top: -1px;
      right: 0;
      width: 70px;
      transform: rotate(180deg);
    }

    &__backgroundImg {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: -20px;
      width: 60px;
    }

    &__imgContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
    }

    &__text {
      letter-spacing: 0.2px;
      text-align: center;
      color: $greyish-brown;
      font-size: 0.9rem;
      position: relative;
      z-index: 10;
    }

    &__signature {
      color: $package-grey;
      font-size: 16px;
      padding-left: 1rem;
    }

    &__name {
      font-weight: 600;
      font-size: 22px;
      color: $greyish-brown;
    }

    &__img {
      vertical-align: middle;
      object-fit: cover;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 1px solid #eeeeee;
    }

    &__next {
      position: absolute;
      right: -27px;
      z-index: 50;
      top: 50%;
      transform: translateY(-50%);
    }

    &__back {
      position: absolute;
      left: -17px;
      z-index: 50;
      top: 50%;
      transform: translateY(-50%);
    }

    &__next:hover {
      svg {
        path {
          fill: $icon-orange !important;
        }
      }
    }

    &__back:hover {
      svg {
        path {
          fill: $icon-orange !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .mentors {
    &__mentor {
      align-items: center;
      justify-content: space-around;
      padding: 4rem 5rem 1.5rem 5rem;

      &__wave_blue--left {
        width: 150px;
      }

      &__wave_blue--right {
        width: 150px;
      }

      &__backgroundImg {
        top: -25px;
        width: 80px;
      }

      &__textContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      &__next {
        margin-top: 0;
      }

      &__back {
        margin-top: 0;
      }
    }
  }
}

@media only screen and (min-width: 1300px) and (orientation: landscape) {
  .mentors {
    &__mentor {
      &__next {
        right: -45px;
      }
      &__back {
        left: -40px;
      }
    }
  }
}

