.studentStories {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__next {
    position: absolute;
    right: -5px;
    height: 25%;
    align-items: center;
    display: flex;
  }

  &__back {
    position: absolute;
    left: 5px;
    height: 25%;
    align-items: center;
    display: flex;
  }

  &__slideContainer {
    display: flex;
  }

  &__headerContainer {
    padding: 0 1.25rem;
    width: 100%;
  }

  &__header {
    font-size: 1.25rem;
    letter-spacing: 0.4px;
    text-align: left;
    color: $greyish-brown-main;
    margin-top: 0.3rem;
    margin-bottom: 1.3rem;
  }

  &__info {
    font-size: 0.875rem;
    color: $brownish-grey-nav;
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .studentStories {
    &__next {
      right: 3%;
    }

    &__back {
      left: 3%;
    }
  }
}
