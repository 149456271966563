.partnerLogos {
  padding-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;

  &__slider-control-arrow-button {
    background: transparent;
    border: none;
    outline: none;
    padding: 5px;
    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translate(50%);

    &.next {
      right: 10px;
    }

    &.prev {
      left: 1px;
      transform: rotate(180deg);
    }

    &.disabled {
      display: none;
    }
  }

  &__tile {
    background-color: $light;
    padding: 1.25rem;
    border-radius: 10px;
    border: 1px solid $border-grey;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.05);
    }
  }

  &__item {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px 10px 20px;

    & > img {
      max-width: 200px;
    }

    &__text {
      font-size: 0.875rem;
      letter-spacing: 0.1px;
      color: $input-text-color;
    }

    &__header {
      font-size: 1rem;
      letter-spacing: 0.1px;
      color: $input-text-color;
    }
  }
}

@media only screen and (min-width: 768px) {
  .partnerLogos {
    &__container {
      display: flex;
      justify-content: stretch;
    }

    &__header {
      width: 50%;

      & > .desktop-container {
        width: auto;
      }
    }

    &__tile {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
    }

    &__item {
      width: 50%;
      flex-direction: column;
    }
  }
}

@media only screen and (min-width: 1290px) {
  .partnerLogos {
    &__header {
      width: 35%;
    }

    &__tile {
      width: 65%;
    }

    &__item {
      width: 33.3%;
      box-sizing: border-box;
    }
  }
}
