.cookiesConfirmation {
  transition: all 0.3s ease;
  padding: 20px;
  position: fixed;
  bottom: 0;
  z-index: 90;
  background-color: $light;
  font-size: 16px;
  color: #606060;
  text-align: center;
  margin-bottom: 55px;

  &__container {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: justify;
  }

  a {
    color: $orangey-yellow;
  }

  &__btn {
    display: block;
    margin: auto;

    & > .BasicButton__label {
      text-transform: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .cookiesConfirmation {
    width: 100%;
    margin-bottom: 0;
    &__container {
      grid-template-columns: 4fr 1fr;
      grid-gap: 1rem;
      max-width: 41rem;
      margin: auto;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .cookiesConfirmation {
    &__container {
      max-width: 60rem;
    }
  }
}
