.accordionsContainer {
  padding: 5px 15px 20px;

  &Grey {
    background-color: $background-light;
  }
}

.accordion {
  margin-top: 20px;

  &__header {
    position: relative;
    padding-right: 40px;
    padding-bottom: 20px;
  }

  &__content {
    padding-bottom: 10px;
  }

  &__expand {
    border-bottom: 1px solid $border;

    &Icon {
      position: absolute;
      top: calc(50% - 11px);
      right: 10px;

      & > div {
        transition: all ease-in-out 0.2s;
      }

      svg {
        fill: $secondary;
        padding: 0 15px;
        width: 11px;
        height: 17px;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .accordionsContainer {
    padding: 5px 0;
    margin-bottom: 40px;
  }
}
