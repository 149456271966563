/* Color palette */

$orangey-yellow: #fbbb21;
$ocean-green: #2bc4b6;
$icon-orange: #fdb933;
$red: #ff0000;
$welcome-grey: #6e6e6e;
$greyish-brown-main: #4a4a49;
$greyish-brown: #3d3d3d;
$warm-grey-item: #6c6b6b;
$warm-grey-details: #6f6f6f;
$arrow-white: #e9e9e9;
$brownish-grey-nav: #606060;
$pinkish-grey: #bbbbbb;
$greyish-brown-elements: #3c3c3c;
$white: #ffffff;
$white-icon: #e3e3e3;
$white-divider: #ebebeb;
$white-background: #f3f3f3;
$white-two: #efefef;
$white-three: #f4f4f4;
$warm-grey-text: #4d4d4f;
$greyish-brown-card: #4c4c4c;
$pinkish-grey-two: #bababa;
$pinkish-grey-three: #bcbcbc;
$black: #000000;
$grey-divider: #d7d7d7;
$grey-border: #d7d7d7;
$greyish: #b6b6b6;
$white-four: #f9f9f9;
$light-grey: #eeeeee;
$border-color: #e5e4e2;
$package-light-grey: #919190;
$package-dark-grey: #292f2f;
$package-grey: #717171;
$dark-grey: #2a3030;
$friday-black: #2b2f37;
$light-grey-background: #f8f8f8;
$orange-background: #f8b833;
$button-grey-text: #2b2f30;
$modern-button-shadow: #0f1010;
$dark-blue: #1c1558;
$list-item: #f6f7f9;
$list-item-hover: #d5edf4;
$list-action-button: #63d0ee;
$mobile-header-background: #38475e;
$orange-link-background: #fbb600;
$light-grey-text: #eff0f2;
$light-blue-background: #d5edf4;
$dark-blue-background: #263451;
$timeline-item-background: #fcbf1f;
$top-bar-color: #69cdeb;
:export {
  icon-orange: $icon-orange;
  greyish-brown: $greyish-brown;
  warm-grey-item: $warm-grey-item;
  orangey-yellow: $orangey-yellow;
  icon-orange: $icon-orange;
  red: $red;
  greyish-brown-main: $greyish-brown-main;
  arrow-white: $arrow-white;
  warm-grey-details: $warm-grey-details;
  brownish-grey-nav: $brownish-grey-nav;
  white: $white;
  white-icon: $white-icon;
  pinkish-grey: $pinkish-grey;
  greyish-brown-elements: $greyish-brown-elements;
  white-divider: $white-divider;
  warm-grey-text: $warm-grey-text;
  greyish-brown-card: $greyish-brown-card;
  black: $black;
  pinkish-grey-two: $pinkish-grey-two;
  pinkish-grey-three: $pinkish-grey-three;
  white-background: $white-background;
  light-grey: $light-grey;
  border-color: $border-color;
  package-light-grey: $package-light-grey;
  package-dark-grey: $package-dark-grey;
  package-grey: $package-grey;
  dark-grey: $dark-grey;
  orange-background: $orange-background;
  button-grey-text: $button-grey-text;
  light-grey-background: $light-grey-background;
  modern-button-shadow: $modern-button-shadow;
  dark-blue: $dark-blue;
  list-item: $list-item;
  list-item-hover: $list-item-hover;
  list-action-button: $list-action-button;
  mobile-header-background: $mobile-header-background;
  orange-link-background: $orange-link-background;
  light-grey-text: $light-grey-text;
  light-blue-background: $light-blue-background;
  dark-blue-background: $dark-blue-background;
  timeline-item-background: $timeline-item-background;
  top-bar-color: $top-bar-color;
}

/* ******** */
