.expandableTile {
  padding-top: 1rem;
  box-sizing: border-box;

  &__container {
    margin: 5px 15px 20px;
  }

  &__cardsContainer {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 15px;
  }

  &__cardsDesktop {
    display: flex;
    align-items: stretch;
    column-gap: 20px;

    .expandableTile__card {
      flex-grow: 1;
      width: 33%;
      margin: 0;
      position: relative;

      .expandableTile__cardIcon {
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
    }
  }

  &__cardWrapper {
    margin: auto;
  }

  &__headerContainer {
    max-width: 58rem;
  }

  &__cardIcon {
    text-align: right;
    width: 100%;

    &:hover {
      cursor: pointer;
      svg, path {
        fill: $primary!important;
      }
    }
  }

  &__card {
    background-color: $white;
    padding: 1rem 1.5rem;
    box-shadow: 0 13px 40px 0 rgba(0, 0, 0, 0.05);
    margin: auto auto 3rem;
    display: flex;
    flex-wrap: wrap;
    min-height: 300px;
    border-radius: 10px;
    border: 1px solid $light-grey;
    position: relative;
    box-sizing: border-box;
    justify-content: center;

    & > div >img {
      max-width: 100%;
      width: 100%;
    }

    &__triangle {
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: -35px;
      width: 0;
      height: 0;
      border-top: solid 35px $white;
      border-left: solid 35px transparent;
      border-right: solid 35px transparent;
    }

    &__header {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 1rem;
      min-height: 53px;

      &--biggerMargin {
        margin-top: 1rem;
      }
    }

    &__text {
      line-height: 1.88;
      letter-spacing: 0.2px;
      color: $warm-grey-text;
      font-size: 0.8rem;
      margin-bottom: 3rem;
    }

    &__iconArrow {
      margin-left: auto;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      align-self: flex-end;
      transform: rotate(90deg);
      transition: all 0.2s ease;

      &--active {
        transform: rotate(270deg);

        svg {
          path {
            fill: $orangey-yellow !important;
          }
        }
      }
    }
  }

  &__bottomItem {
    position: relative;
    background-color: $white;
    padding: 2.5rem;
    box-shadow: 0px 9px 76px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid $border-grey;
    margin: 0.5rem auto 2rem;

    &__img {
      width: 100%;
    }

    &__header {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__info {
      letter-spacing: 0.2px;
      color: $warm-grey-text;
      font-size: 0.8rem;
    }

    &__closeBtn {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #000;
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      width: 20px;
      height: 20px;
      padding: 5px;

      & > svg {
        fill: $secondary;
      }
    }

    &__closeBtn:hover {
      cursor: pointer;
    }
  }
}

.downContent {
  margin-top: 50px;
}
