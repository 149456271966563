.movies {
  text-align: center;
  padding: 15px;
  &--horizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__player {
    iframe {
      width: 80vw;
      height: 45vw;

      @media only screen and (min-width: 768px) {
        width: 50vw;
        height: 29vw;
        max-width: 600px;
        max-height: 340px;
      }
    }
  }

  &__picture {
    width: 81vw;
    height: 45vw;

    & > iframe {
      width: 100%;
      height: 100%;
    }

    @media only screen and (min-width: 768px) {
      max-width: 600px;
      max-height: 340px;
      width: 51vw;
      height: 30vw;
    }

    &--play {
      border: 3px solid #fbbb21;
      width: 12vw;
      height: 12vw;
      max-width: 100px;
      max-height: 100px;
      position: absolute;
      z-index: 6;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s ease;
      &:hover {
        background: rgba(0, 0, 0, 0.45);
      }
      @media only screen and (min-width: 768px) {
        border: 5px solid #fbbb21;
      }
      &-button {
        color: #fbbb21;
        max-width: 5vw;
      }
    }
  }

  &__picture--container {
    position: absolute;
    left: 50%;
    z-index: 5;
    transform: translateX(-50%);

    @media only screen and (min-width: 300px) {
      transform: translateX(-50%);
    }

    @media only screen and (min-width: 600px) {
      transform: translateX(-50%);
    }
  }

  &__picture--container--horizontal {
    position: absolute;
    z-index: 1;
  }

  &__paddingsForBlocks {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
