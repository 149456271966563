.top_description {
  text-align: center;
  color: $secondary;
  padding: 0 15px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.tilesInColumns {
  padding-left: 15px;
  padding-right: 15px;
}

.tile {
  &__item {
    display: flex;
    min-height: 100px;
    color: $secondary;
    padding: 15px;
    margin: 0 0 20px;
    box-sizing: border-box;

    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__header {
    margin-top: 15px;
  }

  &__text {
    p {
      margin: 10px 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  .twoColumnsLayout {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .tile {
      &__item {
        margin: 0 0 20px;
        width: calc(50% - 10px);
        box-sizing: border-box;
      }
    }
  }

  .threeColumnsLayout {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .tile {
      &__item {
        width: calc(33% - 5px);
      }
    }
  }

  .top_description {
    padding: 25px;
  }
}
