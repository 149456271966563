.wrapperProjects {
  margin: auto;
}

.projectSlides {
  height: 14rem;
  display: flex;
  justify-content: left;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  margin: auto;
  transition: all 0.5s ease;
  margin-top: -1rem;

  &__column {
    width: 1.25rem;
    background-color: $greyish-brown-main;
    height: 14rem;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 14;
    padding: 0.3rem;
  }

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  &__project {
    display: inline-block;
    width: 100%;
    max-width: 474px;
  }

  &__img {
    height: 100%;
    width: auto;
    filter: grayscale(100%);
    z-index: 1;
    transition: all 0.5s ease;
    transition: z-index 0.2s 1s;
  }

  &__title {
    width: 100%;
    max-width: 474px;
    font-size: 1.125rem;
    text-align: center;
    color: $pinkish-grey-two;
    position: relative;
    top: -4rem;
    z-index: 10;
    transition: all 0.5s ease;
    width: 100%;
    max-width: 474px;
  }

  &__belt {
    height: 4rem;
    width: 100%;
    max-width: 474px;
    background-color: $white-two;
    position: relative;
    z-index: 1;
    opacity: 1;
    align-self: flex-end;
    margin-top: -4rem;
    transition: all 0.5s ease;
  }

  &__active {
    color: $white;
    filter: grayscale(0%);
    transition: all 0.5s ease;
  }

  &__activeBelt {
    opacity: 0;
  }

  &__project:hover > .projectSlides__title {
    color: $white;
  }

  &__project:hover > .projectSlides__img {
    filter: grayscale(0%);
  }

  &__project:hover > .projectSlides__belt {
    opacity: 0;
  }
}

.textWrapper {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.2px;

  color: $greyish-brown-main;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

@media only screen and (min-width: 768px) {
  .projectSlides {
    height: 18rem;
    width: 48rem;

    &__column {
      height: 100%;
    }
  }

  .textWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
}

@media only screen and (min-width: 1024px) {
  .projectSlides {
    margin: auto;
    max-width: 100%;
    width: fit-content;
    height: 381px;

    &__right {
      right: 0;
    }
  }
}
