.error-boundary {
  text-align: center;
  min-height: 300px;

  h1 {
    padding-top: 130px;
    padding-bottom: 40px;
  }

  &__btn {
    width: 220px;
    height: 46px;
    display: block;
    margin: auto;
    padding: 0 1rem;
    border-radius: 28px;
    line-height: 46px;
    text-decoration: none;
    background-color: $orangey-yellow;
    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.07);
    color: #4d4d4d;
  }

  &__text {
    font-size: 1.2rem;
    line-height: 1.667;
    letter-spacing: 0.2px;
    color: $orangey-yellow;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 2rem;
    cursor: pointer;

    &--small {
      margin-top: 0;
      font-size: 0.9rem;
    }
  }
}
