.topContainer {
  background-color: $white-background;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding-top: 1rem;
  border-top: 1px solid white;

  &__text {
    color: $brownish-grey-nav;
    text-decoration: none;
    font-size: 0.875rem;
    min-width: 70%;
    margin-bottom: 16px;
    transition: all 0.3s ease;
  }

  &__column {
    padding: 0 0.5rem;
    display: flex;
    flex-wrap: wrap;

    &--left {
      width: 45%;
    }

    &--right {
      width: 30%;
    }
  }

  .iconsContainer {
    padding: 0.75rem 0px;

    .icon {
      margin: 0px 0.625rem;
      cursor: pointer;
      & > img {
        filter: brightness(0);
        transition: all 0.2s ease-in-out;
        max-height: 36px;
      }
    }


    .icon:hover > img {
      filter: none;
    }
  }
}

.bottomContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0px;

  &__text {
    margin-right: auto;
    align-self: center;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.86;
    letter-spacing: 0.1px;
    color: $pinkish-grey;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }

  &__text:hover {
    font-weight: bold;
  }

  .iconsContainer {
    padding: 0.75rem 0px;

    .icon {
      margin: 0px 0.625rem;
      cursor: pointer;
      & > img {
        filter: brightness(0);
        transition: all 0.2s ease-in-out;
        max-height: 32px;
      }

      svg {
        path {
          fill: $pinkish-grey-three !important;
        }
      }
    }

    .icon:hover > img {
      filter: none;
    }

    .icon:hover > svg {
      path {
        fill: $icon-orange !important;
      }
    }
  }
}

.topContainer__text:hover {
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .topContainer {
    height: 95px;
    flex-wrap: nowrap;
    padding-top: 0;
    justify-content: center;

    &__text {
      margin-bottom: 0px;
      min-width: 0px;
      width: auto;
      font-size: 0.75rem;
      margin: 0.5rem;
      text-decoration: none;
      text-align: center;
    }
  }

  .bottomContainer {
    height: 107px;
    flex-wrap: nowrap;

    &__text {
      font-size: 0.75rem;

      width: auto;
      display: inline;
      margin-right: 1rem;

      &--first {
        margin-left: 0.5rem;
      }
    }

    .iconsContainer {
      margin-left: auto;
      padding: 0px;
      order: 10;

      .icon {
        &--last {
          margin-right: 3.5625rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .topContainer {
    height: 95px;
    flex-wrap: nowrap;
    padding-top: 0;

    &__text {
      margin-bottom: 0px;
      font-size: 0.875rem;
      width: auto;
      margin: 0;
      margin-right: 1.1rem;
      text-decoration: none;
    }
  }

  .bottomContainer {
    height: 107px;
    flex-wrap: nowrap;

    &__text {
      font-size: 0.875rem;

      width: auto;
      display: inline;
      margin-right: 2rem;

      &--first {
        margin-left: 3.375rem;
      }
    }

    .iconsContainer {
      margin-left: auto;
      padding: 0px;
      order: 10;
      margin-right: 2.5rem;

      .icon {
        &--last {
          margin-right: 3.5625rem;
        }
      }
    }
  }

  .footer__sidebar {
    .topContainer {
      &__text {
        text-align: center !important;
      }
    }

    .bottomContainer {
      .iconsContainer {
        margin-right: 0.5rem !important;
      }
    }

    .bottomContainer__text--first {
      margin-left: 1.375rem !important;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .topContainer {
    height: 95px;
    flex-wrap: nowrap;

    &__text {
      text-align: center;
      margin-bottom: 0px;
      width: auto;
      margin: 1.5rem;
      text-decoration: none;
    }
  }
}
