.questions {
  overflow: hidden;

  &__dropDownContent {
    font-size: 14px;
    line-height: 20px;
  }

  &__header {
    color: $dark-grey;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 20px 0;
  }

  a {
    color: $orangey-yellow;
  }
}

.collapseItem {
  transition: all 0.3s ease-in-out 0s;
  padding: 0 1.25rem;
  position: relative;
  border-bottom: 1px solid $greyish;

  &:last-child {
    border-bottom: none;
  }

  &__content {
    position: relative;
  }

  &__headerText {
    max-width: 90%;
    font-size: 16px;
    font-weight: bold;
  }

  &__header {
    cursor: pointer;
    color: $greyish-brown-main;
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;
  }

  &__icon {
    margin-left: auto;
    position: absolute;
    right: 0.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .questions {
    &__dropDownContent {
      font-size: 16px;
      line-height: 24px;
    }
    &__header {
      font-size: 36px;
      padding: 40px 0;
    }
  }

  .collapseItem {
    padding: 0 2.5rem;

    &__content {
      position: relative;
      max-width: 68rem;
      margin: auto;
    }

    &__header {
      margin: 35px 0;
    }

    &__headerText {
      font-size: 18px;
    }
    &__icon {
      right: 2rem;
    }
  }
}
