.twoColumnsSlider {
  background-color: $background-light;

  &__container {
    color: $secondary;
    padding: 40px 0;
    position: relative;

    &--white {
      background-color: $light;
    }

    &--gray {
      background-color: $background-light;
    }

    .react-multi-carousel-list {
      margin: 0 auto;
      width: 90%;
    }

    .react-multi-carousel-track {
      margin: 0 auto;
      align-items: stretch;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    max-width: 370px;

    & > img {
      width: 100%;
    }
  }

  &__content {
    box-sizing: border-box;

    h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
    }

    &Description {
      font-style: italic;
    }
  }

  &__buttonsGroup {
    display: none;

    & > button {
      background: transparent;
      outline: none;
      border: none;
      top: calc(50% + 20px);
      position: absolute;
      transition: all 0.25s ease-in;

      & > svg {
        height: 20px;
        position: absolute;
        z-index: 2;
        transition: all 0.25s ease-in;
      }
    }

    &--left {
    }
    &--right {
      transform: scaleX(-1);
    }
  }
}

@media (min-width: 768px) {
  .twoColumnsSlider {
    &__item {
      flex-direction: row;
    }

    &__picture {
      flex: 0 0 40%;

      & > img {
        width: 100%;
      }
    }

    &__content {
      flex: 0 0 60%;
      padding-left: 40px;
      padding-right: 40px;
    }

    &__buttonsGroup {
      display: block;

      & > button {
        top: calc(50% - 40px);

        & > svg {
          height: 40px;
        }

        &:after {
          content: "";
          height: 35px;
          width: 35px;
          position: relative;
          border: 2px solid $primary;
          border-radius: 100%;
          top: 0;
          display: block;
        }
      }
      &--left {
        left: 20px;
      }

      &--right {
        right: 20px;
      }
    }

    &__header {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin: 0 auto;
      width: 90%;
      padding-left: 20px;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
}

@media (min-width: 1360px) {
  .twoColumnsSlider {
    &__container {
      .react-multi-carousel-list {
        width: 1270px;
      }
    }

    &__header {
      width: 1270px;
      margin: auto;
    }

    &__buttonsGroup {
      &--left {
        left: calc((100% - 1270px) / 2);
      }

      &--right {
        right: calc((100% - 1270px) / 2);
      }
    }
  }
}
