.addressContainer {
  display: flex;
  flex-wrap: wrap;

  &__street {
    width: 70% !important;
  }

  &__nr {
    width: 30% !important;
  }

  &__postalCode {
    width: 40% !important;
  }

  &__city {
    width: 60% !important;
  }

  &__country {
    width: 10% !important;
  }

  &__bankAccount {
    width: 90% !important;
  }
}
