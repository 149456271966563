.anchors-hide-on-mobile {
  display: none;
  visibility: hidden;
}

.anchors-container-background {
  &-white {
    background-color: $light;
    width: 100%;
    box-shadow: 0 4px 12px 0 rgba(0 0 0/ 10%);
    margin-bottom: 10px;
  }

  &-grey {
    background-color: $background-light;
    width: 100%;
  }
}

.anchors-container-sticky {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0 4px 12px 0 $border;
}

.anchors-container {
  display: block;
  padding: 0;

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  .react-horizontal-scrolling-menu--item {
    display: inline-block;
    white-space: nowrap;
  }

  .menu-item {
    padding: 20px 10px;
    user-select: none;
    cursor: pointer;
    display: flex;

    &:hover {
      text-shadow: 0 0 1px $secondary;
    }

    & > a {
      text-decoration: none;
      color: $secondary !important;
    }
  }

  .scroll-menu-arrow {
    padding: 10px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
  }

  .scroll-menu-arrow--disabled {
    visibility: hidden;
  }
}

@media only screen and (min-width: 990px) {
  .anchors-hide-on-mobile {
    display: block;
    visibility: visible;
  }

  .anchors-container {
    padding: 0 15px;

    .menu-item {
      padding: 20px;
    }
  }
}

@media only screen and (min-width: 1360px) {
  .anchors-container {
    &.desktop-container {
      width: 1326px;
    }
  }
}
