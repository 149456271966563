.formsySelect {
  max-width: 41.7rem;
  margin-bottom: 1rem;

  .Select-control {
    padding: 0.8rem;
    border: none;
    color: $greyish-brown-elements;
    font-size: 0.9rem;
    width: 100%;
    text-align: left;
    margin: 2px;
    padding-left: 2rem;
    background-color: $white-four;
  }

  .Select-placeholder {
    padding: 0.9rem;
  }

  .Select-value {
    padding: 0.9rem;
    color: $greyish-brown-elements;
    font-weight: bold;
    font-size: 0.9rem;
  }

  .Select-menu-outer {
    z-index: 50;
  }
}

.formsySelect.Select-control__error {
  .Select-control {
    border: 1px solid red !important;
  }
}
