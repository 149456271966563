@import "styles/colors";

.notFound {
  margin: auto;
  text-align: center;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 590px;
    margin: auto;
    margin-bottom: -2rem;
  }

  &__header {
    margin-top: 80px;
    letter-spacing: 0.6px;
    color: $greyish-brown-main;
    font-size: 1.3rem;
    font-weight: normal;
    margin-bottom: 1rem;
  }

  &__info {
    color: $greyish-brown-elements;
    font-weight: 300;
    font-size: 0.9rem;
    width: 80%;
    margin-bottom: 0;

    &--small {
      font-size: 0.7rem;
    }
  }

  &__img {
    width: 30%;
    margin-top: 2rem;
  }

  &__btn {
    width: 221px;
    height: 33px;
    margin-top: 2rem;

    .BasicButton__label {
      font-size: 0.7rem;
    }
  }

  &__waves {
    position: relative;
    z-index: -1;
    bottom: -5px;
    transform: scale(5);
  }
}

@media only screen and (min-width: 768px) {
  .notFound {
    &__content {
      margin-bottom: -9rem;
    }

    &__header {
      font-size: 1.5rem;
    }

    &__info {
      width: 54%;
    }

    &__img {
      width: 22%;
    }

    &__btn {
      margin-top: 4rem;
    }

    &__waves {
      transform: scale(2);
    }
  }
}

@media only screen and (min-width: 1024px) {
  .notFound {
    &__content {
      margin-bottom: -13rem;
    }

    &__header {
      font-size: 2.2rem;
    }

    &__btn {
      width: 302px;
      height: 42px;

      .BasicButton__label {
        font-size: inherit;
      }
    }

    &__waves {
      transform: scale(1.5);
    }
  }
}

@media only screen and (min-width: 1440px) {
  .notFound {
    &__content {
      margin-bottom: -16rem;
    }

    &__header {
      font-size: 2.4rem;
    }

    &__info {
      font-size: 1rem;

      &--small {
        font-size: 0.85rem;
      }
    }

    &__waves {
      transform: scale(1);
    }
  }
}

@media only screen and (min-width: 1600px) {
  .notFound {
    &__content {
      margin-bottom: -16rem;
    }

    &__waves {
      transform: scale(1);
    }
  }
}
