.sliderWithTabs {
  padding: 20px 15px 30px;

  .slider {
    height: auto;
  }

  & > div > h2 {
    text-align: center;
    margin-bottom: 0;
  }

  &__tabContent {
    text-align: start;
  }

  &__slide {
    & > h2 {
      text-align: start;
      margin-bottom: 30px;
      margin-top: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;

    & > p {
      text-align: left;
    }

    .rc-tabs {
      border: none;
      padding: 0;
    }

    .rc-tabs-nav {
      margin-bottom: 20px;
    }

    .rc-tabs-ink-bar {
      display: none;
    }

    .rc-tabs-tab > .rc-tabs-tab-btn {
      border-bottom: 2px solid transparent;
    }

    .rc-tabs-tab.rc-tabs-tab-active > .rc-tabs-tab-btn {
      border-bottom: 2px solid $primary;
    }

    .rc-tabs-tab {
      font-size: 15px;
      font-weight: 400;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .rc-tabs-tabpane {
      outline: none;
    }

    .rc-tabs-tab-btn {
      outline: none;
      user-select: none;
    }

    .rc-tabs-tab-active {
      color: $primary;
      font-weight: 400;
    }

    .rc-tabs-tabpane-active {
      animation: fadein 1s;
    }

    .rc-tabs-nav-wrap-ping-left::before,
    .rc-tabs-nav-wrap-ping-right::after {
      border-color: $menu-background;
    }

    .rc-tabs-nav-more {
      border: 1px solid $menu-background;
      background: transparent;
      border-radius: 0;
      border-left: none;
    }

    .rc-tabs-nav-list {
      flex-wrap: wrap;
    }
  }

  .carousel-root {
    margin-top: 50px;
  }

  .carousel.carousel-slider {
    overflow: initial;
  }

  .carousel .slide {
    background: $light;
  }

  .control-dots {
    .dot {
      border: 2px solid $light;
      opacity: 1;
      outline: none;
      margin: 0 4px;
      box-shadow: 0 0 0 2px $box-shadow !important;

      &.selected {
        opacity: 1;
        background-color: $primary;
      }
    }
  }

  &__arrow {
    display: none;
  }
}

@media (min-width: 768px) {
  .sliderWithTabs {
    padding-left: 0;
    padding-right: 0;

    &__item {
      flex-direction: row;
      padding-bottom: 40px;

      & > p {
        padding-right: 15px;
      }

      &--reverse {
        flex-direction: row-reverse;
      }

      & > .rc-tabs {
        flex: 0 0 50%;
        padding-right: 90px;
      }
    }

    &__rightSection {
      flex: 0 0 50%;
      text-align: start;

      & > img {
        max-width: 635px;
        width: auto !important;
      }
    }
  }
}

@media (min-width: 1288px) {
  .sliderWithTabs {
    &__arrow {
      display: block;
      top: 0;
      bottom: 0;
      transition: all 0.25s ease-in;
      position: absolute;
      z-index: 2;
      background: none;
      border: 0;
      cursor: pointer;
      outline: 0;

      & > svg {
        height: 20px;
        position: absolute;
        z-index: 2;
        transform: translateX(0);
        transition: all 0.4s ease-in-out;
      }

      &:after {
        content: "";
        height: 35px;
        width: 35px;
        position: relative;
        border: 2px solid $primary;
        border-radius: 100%;
        top: -10px;
        display: block;
      }

      &:hover {
        & > svg {
          transform: translateX(-14px);
        }
      }

      &--next {
        right: -90px;
        transform: scaleX(-1);
      }

      &--prev {
        left: -90px;
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
