.textWithMap {
  &__container {
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column-reverse;
  }
  &__imgContainer > img {
  width: 100%;
  }

    &__item {
    color: $brownish-grey-nav;
    text-align: center;

    &__header {
      font-weight: 600;
      line-height: 1.01;
      letter-spacing: 0.9px;
      font-size: 3rem;
      margin-bottom: 0;
      color: #bfbfbf;
    }

    &__subHeader {
      line-height: 1.25;
      letter-spacing: 0.2px;
      color: $greyish-brown-main;
      font-size: 1.2rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    &__info {
      margin-top: 0.5rem;
      line-height: 1.29;
      letter-spacing: 0.1px;
      font-size: 0.8rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .textWithMap {
    &__container {
      padding: 0 2.5rem;
      flex-direction: row;
      grid-template-columns: 1.5fr 1fr;
      grid-gap: 1rem;
    }

    &__imgContainer {
      width: 60%;
      margin: auto 4rem auto 0;
    }

    &__img {
      max-width: 100%;
      height: auto;
    }

    &__item {
      text-align: left;

      &__header {
        margin-top: 1rem;
      }

      &__info {
        margin-bottom: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 1290px) {
  .textWithMap {
    &__container {
      padding: 0;
    }
  }
}
