.formsyCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 1rem;

  &__wrong {
    border: 1px solid red !important;
    box-shadow: 0 0 65px 0 rgba(0, 0, 0, 0.07);
  }
}

/* Hide the browser's default checkbox */
.formsyCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border: 1px solid $grey-border;
}

/* On mouse-over, add a grey background color */
.formsyCheckbox:hover input ~ .checkmark {
  background-color: $white;
  border: 1px solid $icon-orange;
}

/* When the checkbox is checked, add a blue background */
.formsyCheckbox input:checked ~ .checkmark {
  background-color: $white;
  border: 1px solid $icon-orange;
}

/* Create the checkmark/indicator (hidden when not checked) */
.formsyCheckbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.formsyCheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.formsyCheckbox .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid $icon-orange;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.formsyCheckbox__text {
  margin: 0;
  text-align: left;
  padding-left: 12px;
  font-size: 0.875rem;
  font-weight: 400;
}

input:checked ~ .formsyCheckbox__text {
  font-weight: 800;
}
