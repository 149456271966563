.listWithBullets {
  &__container {
    list-style-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10px" height="10px"> <path fill-rule="evenodd"  fill="rgb(251, 187, 33)" d="M-0.000,-0.000 L10.000,-0.000 L10.000,10.000 L-0.000,10.000 L-0.000,-0.000 Z"/></svg>');
    display: grid;
    grid-template-columns: 1fr;
  }

  &__listItem {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .listWithBullets {
    &__container {
      grid-template-columns: 1fr 1fr;
      padding-left: 20px;
    }

    &__listItem {
      text-align: left;
      width: 90%;
      margin-right: 1rem;
    }
  }
}
