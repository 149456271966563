.story {
  flex-wrap: wrap;
  transition: all 0.3s ease;
  align-items: center;
  box-shadow: 0px 26px 79px 0 rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  border: 1px solid transparent;
  max-width: 340px;

&__textContainer {
    padding: 1.25rem;
    padding-top: 0;
  }

  a {
    color: $orangey-yellow;
  }

  &__imgContainer {
  }

  &__img {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  &__name {
    color: $greyish-brown-main;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0;
    width: 100%;
  }

  &__position {
    font-size: 0.875rem;
    margin: 0 auto;
    color: $greyish-brown-main;
    width: 100%;
  }

  &__content {
    width: 100%;
    line-height: 1.71;
    color: $warm-grey-details;
    font-size: 0.875rem;
    height: 52%;
  }
}

@media only screen and (min-width: 768px) {
  .story {
    margin-right: 1rem;
  }
}

@media only screen and (min-width: 1290px) {
  .story {
    box-shadow: none;
    max-width: none;
    width: 33.33%;
  }

  .story:hover {
    background-color: $white;
    box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.05);
    border-color: $light-grey;
  }
}
