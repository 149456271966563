$light: #fff;
$primary: #faba20;
$secondary: #2a3030;
$menu-background: rgba(42, 48, 48, 0.98);
$menuLabel-gray: #969999;
$additional-text: #949797;
$background-light: #f4f4f4;
$border: #0000001a;
$box-shadow: #9a9a9a29;
$input-text-color: #4d4d4f;
$input-border: #ccc;
$error: #ff0000;
$black: #000;
$border-grey: #eee;
$success: #32BEA6;
$flag: #E8215D;
