.contactCa {
  color: $secondary;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    padding: 90px 15px 15px 15px;
    box-shadow: 0 0 12px 0 $border;
    border-radius: 10px;
    margin: 100px 0 24px;

    & > img {
      display: none;
    }

    &:after {
      content: "";
      border-bottom: 1px solid $border;
      position: absolute;
      width: 100%;
      bottom: -30px;
    }
  }

  &__mobileImage {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 150px;
    border: 1px solid $border;
    box-sizing: border-box;
    position: absolute;
    top: -75px;
  }

  &__patronName {
    font-size: 24px;
  }

  &__patronCity {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  &__contact {
    font-size: 20px;

    a {
      text-decoration: none;
      color: $secondary !important;
      &:hover {
        text-decoration: underline;
      }
    }

    path {
      fill: $primary !important;
    }

    &--marginRight {
      margin-right: 5px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .contactCa {
    &__item {
      flex-direction: row;
      width: 50%;
      text-align: start;
      height: 120px;
      margin: 24px 0;
      padding: 0;
      box-shadow: none;
      border-radius: unset;

      & > img {
        display: inline-block;
        max-width: 100px;
        height: auto;
        margin-right: 15px;
      }

      &:after {
        display: none;
      }
    }

    &__contact {
      font-size: 12px;
    }

    &__patronName {
      font-size: 20px;
    }

    &__patronCity {
      font-size: 14px;
    }

    &__mobileImage {
      display: none;
    }
  }
}

@media only screen and (min-width: 1290px) {
  .contactCa {
    padding: 50px 0;

    &__item {
      width: 33%;
      & > img {
        max-width: 80px;
      }
    }

    &__contact {
      font-size: 14px;
    }
  }
}
