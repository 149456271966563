.twoColumns {
  color: $dark-grey;

  &--contentContainer {
    padding: 0 15px;
  }

  .marginRightDesktop-30 {
    margin-right: 0;
  }

  &--imgContainer {
    & > img {
      height: auto;
      width: 100%;
    }
  }

  .movie__container {
    margin: 0;
  }

  &--divider {
    height: 4px;
    width: 100px;
    background-color: $orange-background;
    margin-top: 10px;
  }

  &--header {
    font-weight: bold;
  }

  &--buttonsContainer {
    display: flex;
    padding-top: 15px;
    flex-wrap: wrap;

    a {
      margin-right: 20px;
      margin-bottom: 15px;
      text-decoration: none;
      font-weight: bold;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &--baseButton {
    line-height: 44px;
  }
}

@media (min-width: 999px) {
  .twoColumns {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--divider {
      margin-top: 0;
    }

    .marginRightDesktop-30 {
      margin-right: 30px;
      padding-left: 0;
    }

    &--imgContainer {
      & > .movie {
        padding-bottom: 0;
      }

      & > img {
        max-height: 450px;
        width: auto;
      }
    }

    .movie__container {
      width: 81vw;
      height: 45vw;
      max-width: 600px;
      max-height: 340px;
    }
  }
}
