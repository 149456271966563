.contactBlock {
  padding: 40px 15px;
  background-size: cover;
  background-position: center;
  position: relative;

  h2 {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 30px;
  }

  &__arrowsImage {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .contactFormSection {
      .Select__control {
        border-radius: 30px;
      }

      .Select__menu {
        z-index: 10;
        @media (min-width: 768px) {
          width: 50%;
        }
      }

      .Select__menu-list {
        color: $black;
      }

      .Select__option--is-selected {
        background-color: $primary;
        color: $light;
      }

      .Select__option--is-focused {
        background-color: $primary;
        color: $light;
      }

      &__patron {
        .patronAvatar {
          vertical-align: middle;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 3px solid $light;
        }
      }

      form {
        margin-top: 15px;
        display: inline-block;

        & > input {
          border-radius: 58px;
          border: none;
          outline: none;
          line-height: 50px;
          margin-right: 10px;
          padding: 0 20px;
          font-size: 16px;
          font-family: inherit;
          width: calc(65% - 10px);
          box-sizing: border-box;
        }

        & > input[type="submit"] {
          width: calc(35% - 10px);
          max-width: 280px;
          margin-right: 0;

          &:disabled {
            filter: grayscale(1);
            cursor: not-allowed;
          }
        }
      }

      .contactFormPolicy {
        display: flex;
        margin: 10px 0;
        font-size: 13px;

        a {
          color: $primary;
        }
      }

      .contactFormSend {
        margin-top: 15px;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        display: flex;
        align-items: center;

        svg {
          fill: $primary;
          margin-right: 15px;
        }
      }
    }

    & > .shopSection {
      display: none;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 200px;
        color: $light;
        text-decoration: none;

        &:hover {
          color: $primary;
        }

        & > svg {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .courseContact {
    &__patron {
      font-size: 16px;
      display: flex;
      flex-direction: row;
      margin-top: 35px;
      line-height: 35px;

      .contactFormError {
        color: $primary;
        padding-left: 20px;
        height: 25px;
        font-size: 14px;
      }

      .patronAvatar {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border: 3px solid $light;
        margin-right: 15px;
        background-size: cover;
        background-position: top center;
        border-radius: 50%;
      }

      .patronName {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .patronContacts {
        display: none;

        &__labels {
          margin-right: 10px;

          &--mobile {
            a {
              display: inline-block;
            }
          }
        }
      }

      .patronContactsMobile {
        display: block;
      }

      a {
        color: $primary;
        font-weight: 500;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .contactBlock {
    padding: 100px 25px 80px 25px;

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;

      & > div {
        flex: 1 1 0;
      }

      & > .contactFormSection {
        .Select {
          &__control {
            max-width: 50%;
          }

          &__menu-outer {
            max-width: 50%;
          }
        }
      }

      & > .contactFormSection {
        padding-right: 40px;
      }

      & > .shopSection {
        display: block;
        padding-left: 40px;
      }
    }

    &__arrowsImage {
      display: initial;
      position: absolute;
      right: -10px;
      bottom: -10px;
      width: 500px;
    }

    .courseContact {
      &__patron {
        font-size: 20px;
        flex-direction: row;

        .patronAvatar {
          width: 80px;
          min-width: 80px;
          height: 80px;
        }

        .patronContacts {
          display: flex;
        }

        .patronContactsMobile {
          display: none;
        }

        form {
          & > input[type="submit"] {
            width: auto;
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .contactBlock {
    &__container {
      & > .contactFormSection {
        padding-right: 70px;

        form {
          padding-left: 105px;
        }
      }

      & > .shopSection {
        padding-left: 70px;
      }
    }
  }
}
