.goToShop {
  &__mainContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 0 auto;
  }

  &__empty {
    text-align: center;
    width: 100%;
    margin-top: 30px;

    .terms-label {
      font-size: 23px;
      margin-bottom: 1rem;
      color: $orange-link-background;
    }

    .contact-label {
      font-size: 25px;
      margin-bottom: 2rem;
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      font-size: 14px;
      font-weight: 700;
      border-radius: 5px;
      padding: 15px 20px;
      border: 0;
      display: inline-block;
      margin-bottom: 40px;
      color: #000000 !important;
      outline: none;
      background-color: $list-action-button;
      text-align: center;
      transition: box-shadow 0.42s cubic-bezier(0.165, 0.84, 0.44, 1);

      &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 0 2px $dark-grey;
      }
    }
  }

  &__productInfo {
    width: 100%;
    background-color: $white-four;
    box-sizing: border-box;
    padding: 35px 8%;

    &--image {
      margin-bottom: 45px;
    }

    img {
      width: 100%;
      height: auto;
    }

    &--bottomSection {
      font-size: 18px;
      margin-bottom: 25px;

      & > p {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
      }
    }

    &--technologySection {
      display: flex;

      & > img {
        height: 25px;
        width: auto;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__productForm {
    width: 100%;
    background-color: $light-blue-background;
    box-sizing: border-box;
    padding: 35px 8%;

    & > h3.productName {
      font-size: 22px;
      font-weight: bold;
      color: $black;
      margin-top: 0;
    }
  }

  &__formRow {
    margin-bottom: 20px;

    & > label {
      display: block;
      margin-bottom: 15px;
      color: $black;
      font-size: 16px;
      font-weight: bold;
    }

    & > select {
      width: 100%;
      height: 40px;
      border-radius: 10px;
      padding: 0 10px;
      background-color: $white;
      outline: none;
    }

    .radio {
      margin-bottom: 5px;

      & > label {
        font-size: 18px;
        color: $black;

        & > input {
          margin-right: 10px;
        }
      }
    }

    &--summary {
      margin: 25px 0;
      border-top: 1px solid $black;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;

      & > span {
        font-size: 16px;
        color: $black;

        &:first-child {
          font-weight: bold;
        }
      }
    }

    & > a.shopLink,
    a.applyLink {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
      outline: none;
      border: none;
      background-color: $orange-link-background;
      transition: box-shadow 0.42s cubic-bezier(0.165, 0.84, 0.44, 1);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $black;

      &:hover {
        box-shadow: inset 0 0 0 2px #434343;
      }
    }

    & > a.applyLink {
      margin-top: 15px;
    }
  }
}

@media (min-width: 768px) {
  .goToShop {
    &__mainContainer {
      width: calc(100% - 80px);
      flex-direction: row;
    }

    &__productInfo {
      width: 40%;
    }

    &__productForm {
      width: 60%;
    }
  }
}

@media (min-width: 1600px) {
  .goToShop {
    &__mainContainer {
      width: 1270px;
    }
  }
}
