@import "../colors";
@import "../BaseColors";

.headerComponent {
  padding: 1.25rem;
  margin-bottom: -1px;

  &__header2 {
    margin-top: -1rem;
  }

  &__bold {
    font-size: 1rem;
    color: $secondary;
    font-weight: bold;
  }

  &__info {
    font-size: 1rem;
    color: $secondary;
    margin-top: 0;

    overflow-x: auto;

    table {
      border: 1px solid #ebebeb;

      td {
        padding: 5px;
        color: $brownish-grey-nav;
      }
    }
  }

  &__info2 {
    font-size: 0.9rem;
  }
}

.white {
  .headerComponent__info,{
    color: $white;
  }

  .headerComponent__divider {
    background-color: $white !important;
  }
}

@media only screen and (min-width: 768px) {
  .headerComponent {
    padding: 1.25rem 2.5rem 1.25rem 2.5rem;

    &__info2 {
      &::before {
        content: "";
        display: inline-block;
        width: 130px;
        height: 3px;
        background: $white-divider;
        margin-right: 18px;
        vertical-align: middle;
      }

      &--white {
        &::before {
          content: "";
          display: inline-block;
          width: 130px;
          height: 3px;
          background: $white;
          margin-right: 18px;
          vertical-align: middle;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .headerComponent {
    padding: 3.75rem 2.5rem 2.5rem 2.5rem;

    &__desktopWrapper {
      max-width: 67rem;
      margin: auto;
    }

    &__header2 {
      margin-top: -2rem;
      margin-bottom: 2rem;
    }

    &__bold {
      font-size: 0.875rem;
    }

    &__info {
      margin-bottom: 2.2rem;
      font-size: 0.875rem;
      overflow: hidden;
    }

    &__info2 {
      font-size: 0.8rem;
    }
  }
}
