.expectations {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    text-align: center;
    max-width: calc(13rem - 2px);
    margin: 1rem;

    &__img {
      margin: auto;
    }

    &__text {
      text-align: center;
      color: $greyish-brown-main;
      font-size: 1.2rem;
      min-height: 44px;
      width: 100%;

      p {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    &__img {
    }
  }

  &__arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 60%;
  }

  &__arrow:hover {
    svg {
      path {
        fill: $icon-orange !important;
      }
    }
  }

  &__nextArrow {
    right: 0;
  }

  &__backArrow {
    padding: 0.2rem;
    left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .expectations {
    &__nextArrow {
      right: 10%;
    }

    &__backArrow {
      left: 10%;
    }
  }
}


@media only screen and (min-width: 1290px) {
  .expectations {
    &__container {
      justify-content: space-between;
    }
  }
}
