.slider {
  background-size: cover;
  background-position-x: center;

  &Container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 45px 0;
    box-sizing: border-box;

    &__title {
      text-align: center;
      width: 85vw;
      max-width: 500px;
      padding: 0 15px 30px 15px;
    }

    &__subtitle {
      font-weight: normal;
    }

    &__slides {
      position: relative;
      width: 88vw;
    }

    .react-multi-carousel-dot-list {
      bottom: -35px;
      padding-right: 20px;
    }
  }

  &__buttonsGroup {
    & > button {
      top: calc(50% - 15px);
      position: absolute;
      background-color: rgba(0, 0, 0, 0);
      border: 0;
      cursor: pointer;
      transition: all 0.3s ease;
      outline: none;
      padding: 0;

      & > svg {
        width: 15px;
        height: 15px;
        fill: $secondary;
        border-radius: 40px;
      }
    }

    &--right {
      right: -20px;
      transform: rotate(180deg);
    }

    &--left {
      left: -20px;
    }
  }

  .custom-dot {
    color: $light;
    font-size: 20px;
    padding: 6px;
    line-height: 7px;
    border: 2px solid transparent;
    margin-right: 9px;
    cursor: pointer;
    user-select: none;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .custom-dot--active {
    border-color: $light;
    border-radius: 15px;
  }

  &__singleSlide {
    background-color: $light;
    border-radius: 10px;
  }
}

@media (min-width: 600px) {
  .slider {
    &__buttonsGroup {
      &--right {
        right: -50px;
      }

      &--left {
        left: -50px;
      }

      & > button {
        & > svg {
          width: 20px;
          height: 20px;
          padding: 5px;
        }
      }
    }
  }
}

@media (min-width: 960px) {
  .slider {
    height: auto;
    padding: 40px 0;

    &Container {
      flex-direction: row;
      height: 100%;
      padding: 0 20px;

      &__layout {
        &--left {
          flex-direction: row-reverse;
          padding: 0 76px;
          box-sizing: border-box;
        }

        &--right {
          flex-direction: row;
        }
      }

      &__title {
        width: 50%;
        padding: 0 50px 0 0;
        text-align: left;
      }

      &__subtitle {
        font-weight: normal;
      }

      &__slides {
        width: 50%;
      }

      .react-multi-carousel-dot-list {
        padding-right: 70px;
      }
    }

    &__buttonsGroup {
      & > button {
        & > svg {
          background: $light;
        }
      }
      &--right {
        right: 26px;
      }

      &--left {
        left: -70px;
      }
    }

    &__singleSlide {
      background-color: white;
      width: calc(100% - 100px);
      border-radius: 10px;
    }
  }
}

@media (min-width: 1600px) {
  .slider {
    padding: 70px 0;

    &Container {
      height: 100%;

      &__layout {
        &--left {
          padding: 0;

          .slider__singleSlide {
            width: 470px;
            margin-left: 40px;
          }

          & > .sliderContainer__title {
            padding: 0 0 0 180px;
          }
        }

        &--right > .sliderContainer__title {
          padding: 0 180px 0 0;
        }
      }
    }

    &__buttonsGroup {
      &--right {
        right: -30px;
      }

      &--left {
        left: -100px;
      }
    }

    &__singleSlide {
      background-color: white;
      width: 550px;
      border-radius: 10px;
    }
  }
}
