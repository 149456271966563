.teamSection {
  padding-top: 1rem;
  padding-bottom: 3rem;

  &--dark {
    background-color: $white-background;
  }

  &--top {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  &__cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__card {
    width: 8.5rem;
    margin: 0.5rem;
    background-color: $white;
    letter-spacing: 0.2px;

    color: $greyish-brown;
    font-size: 0.9rem;
    transition: all 0.3s ease;

    &--founder {
      width: 16.125rem;
      margin: 1.25rem;

      &__textContainer {
        padding: 0 1.25rem 1rem 1.25rem;
      }
    }

    &__textContainer {
      padding: 0 1rem 0rem 1rem;
    }

    &__img {
      height: auto;
      width: 100%;
      margin: auto;
      display: block;
    }

    &__name {
      font-weight: bold;
      width: 100%;
      margin-bottom: 0;
      padding: 0 0.5rem;
    }

    &__function {
      margin-top: 0;
      padding: 0 0.5rem;
      text-align: left;
    }
  }

  &__card:hover {
    box-shadow: 0px 12px 38px 0 rgba(0, 0, 0, 0.13);
  }
}

@media only screen and (min-width: 375px) {
  .teamSection {
    &__card {
      width: 10rem;

      &--founder {
        width: 16.125rem;
        margin: 1.25rem;
      }
    }
  }
}

@media only screen and (min-width: 425px) {
  .teamSection {
    &__card {
      width: 12rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .teamSection {
    &__card {
      width: 10rem;

      &__name {
        padding: 0 1.25rem;
      }

      &__function {
        padding: 0 1.25rem;
      }

      &--founder {
        width: 16.125rem;
        margin: 1.25rem;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .teamSection {
    padding-top: 2.5rem;

    &__card {
      width: 14rem;

      &--founder {
        width: 16.125rem;
        margin: 1.25rem;
      }
    }

    &__headerContainer {
      width: 49%;

      & > .headerComponent > .desktop-container {
          max-width: 635px;
      }
    }

    &__desktopWrapper {
      display: flex;
      max-width: 75rem;
      margin: auto;

      .headerComponenent {
        padding: 1.25rem 2.5rem 1.25rem 2rem !important;
      }
    }

    &__cardContainer {
      &--desktop {
        width: 50%;
        margin-left: auto;
        flex-wrap: nowrap;
        padding-top: 4.45rem;
        justify-content: flex-end;
        margin-right: 2rem;
      }

      &--bottom {
        max-width: 85rem;
        margin: auto;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .teamSection {
    &__cardContainer {
      &--desktop {
        margin-right: 0rem;
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .teamSection {
    &__desktopWrapper {
      max-width: 85rem;
    }

    &__card {
      width: 300px;
      margin: 1.25rem;

      &__cardContainer {
        &--desktop {
          padding-top: 2rem;
        }
      }

      &__textContainer {
        padding: 0 1.25rem 1rem 1.25rem;
      }
    }
  }
}
