.productSlider {
  &__container {
    color: $dark-grey;
    background-color: $light-grey-background;
    padding: 40px 0;
    position: relative;

    .react-multi-carousel-list {
      margin: 0 auto;
      width: 90%;
    }

    .react-multi-carousel-track {
      margin: 0 auto;
      align-items: stretch;
    }
  }

  &__header {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;

    &--link {
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      & > svg {
        margin-left: 5px;
        transform: rotate(180deg);
        height: 8px;
      }
    }
  }

  &__buttonsGroup > button {
    &.productSlider__buttonsGroup--left {
      left: 5px;
    }

    &.productSlider__buttonsGroup--right {
      right: 5px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--imgContainer {
      width: 100%;
      text-align: center;
      padding: 20px 0 15px 0;
      border-radius: 10px 10px 0 0;
      position: relative;

      & > a > img {
        max-width: 17.625rem;
        height: auto;
      }
    }

    &--bestseller {
      position: absolute;
      color: $light;
      background-color: $primary;
      padding: 4px 10px;
      border-radius: 16px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      right: 15px;
      top: 30px;
      z-index: 2;
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    padding-bottom: 20px;
    border-radius: 10px;
    margin: 0 10px;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;

    &--title {
      font-weight: 600;
      margin: 15px 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;

      a {
        color: $secondary !important;
        text-decoration: none;
      }
    }

    &--description {
      font-size: 14px;
      line-height: 21px;
      color: $secondary;
      font-weight: 400;
      margin-bottom: 20px;
      margin-top: 0;
      padding: 0 20px;
    }

    &--attributes {
      width: 100%;
      padding: 0 20px 30px;
      box-sizing: border-box;
      font-size: 12px;

      & > div {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        & > span > svg {
          margin-right: 10px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &--link {
      color: $dark-grey !important;
      text-decoration: none;
      margin-bottom: 10px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      & > svg {
        fill: $dark-grey;
        margin-left: 5px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__buttonsGroup > button {
    background: none;
    outline: none;
    border: none;
    top: 320px;
    position: absolute;
    transition: all 0.25s ease-in;

    & > span > svg {
      height: 30px;
      width: 10px;
      position: absolute;
      z-index: 2;
      transition: all 0.25s ease-in;
    }

    .mobileArrow > svg {
      transform: scaleX(-1);
    }

    .desktopArrow {
      display: none;
    }

    &.productSlider__buttonsGroup--left > span > svg {
      left: 3px;
    }

    &.productSlider__buttonsGroup--right > span > svg {
      transform: scaleX(1);
      right: 5px;
    }
  }

  &__labelWitDot {
    &:after {
      content: "\2022";
      color: $primary;
      margin: 0 5px;
    }

    &:last-of-type:after {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .productSlider {
    &__header {
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }

    &__buttonsGroup > button {
      top: calc(50% - 40px);
      .desktopArrow {
        display: initial;
      }

      .mobileArrow {
        display: none;
      }

      & > span > svg {
        height: 40px;
        width: 30px;
      }

      &:after {
        content: "";
        height: 35px;
        width: 35px;
        position: relative;
        border: 2px solid $primary;
        border-radius: 100%;
        top: 0;
        display: block;
      }

      &.productSlider__buttonsGroup--left > span > svg {
        left: 25px;
      }

      &.productSlider__buttonsGroup--right > span > svg {
        transform: scaleX(-1);
        right: 25px;
      }

      &:hover {
        cursor: pointer;

        &.productSlider__buttonsGroup--left > span > svg {
          left: 10px;
        }

        &.productSlider__buttonsGroup--right > span > svg {
          right: 10px;
        }
      }
    }
    &__header {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin: 0 auto;
      width: 90%;
      padding-left: 20px;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
}

@media (min-width: 1800px) {
  .productSlider {
    &__container {
      .react-multi-carousel-list {
        width: 1270px;
      }
    }

    &__header {
      width: 1270px;
      margin: auto;
    }

    &__buttonsGroup > button {
      &.productSlider__buttonsGroup--left {
        left: calc((100% - 1270px) / 2 - 110px);
      }

      &.productSlider__buttonsGroup--right {
        right: calc((100% - 1270px) / 2 - 110px);
      }
    }
  }
}
